import { Component, Input, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-next-baselib";
import { MediaItemsService } from "./mediaitems.service";
import { TranslateService } from "@ngx-translate/core";
import { MediaItemDetailModel } from "./viewmodels/MediaItem";
import { RoomMediaItemTypeArray } from "@impacgroup/gsk-event-platform-api-dtos";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BsModalRef } from "ngx-bootstrap/modal";
import { PermissionService } from "@impacgroup/angular-next-oauth-protection";
import { Observable } from "rxjs";

@Component({
    selector: "app-mediaitemdetail",
    templateUrl: "./mediaitemdetail.component.html",
    styleUrls: ["mediaitemdetail.component.scss"],
})
export class MediaItemDetailComponent extends BaseComponent implements OnInit {
    @Input()
    public override MODE?: BaseComponentMode | undefined;

    @Input()
    public modalRef?: BsModalRef;

    @Input()
    public roomId?: string;

    @Input()
    public mediaItemId?: string;

    @Output()
    public mediaItemsListChanged = new EventEmitter<void>();

    public mediaItem?: MediaItemDetailModel;
    public roomMediaItemTypes = RoomMediaItemTypeArray;
    public selectedFile?: File | SafeUrl;
    public selectedThumbnail?: File | SafeUrl;

    public permissionGranted = false;

    constructor(
        private route: ActivatedRoute,
        private mediaItemsService: MediaItemsService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private sanitization: DomSanitizer
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.roomId) {
            throw Error("Room id must be passed as input parameter");
        }

        if (this.mediaItemId) {
            this.initEditMode();
        } else {
            this.initAddMode();
        }
    }

    initAddMode() {
        if (!this.roomId) {
            return;
        }

        this.permissionGranted = this.permissionService.hasPermission("ROOMMEDIAITEMS.ADD");
        this.MODE = BaseComponentMode.ADD;
        this.mediaItem = {};
    }

    initEditMode() {
        if (!this.roomId) {
            return;
        }

        this.permissionGranted = this.permissionService.hasPermission("ROOMMEDIAITEMS.EDIT");
        this.MODE = BaseComponentMode.EDIT;

        if (this.mediaItemId) {
            this.subscriptions.push(
                this.mediaItemsService.read(this.roomId, this.mediaItemId).subscribe({
                    next: (result) => {
                        this.mediaItem = result;

                        if (this.roomId && this.mediaItemId) {
                            this.downloadImageFile({ filename: this.mediaItem.thumbnail, imagepropertyname: "selectedThumbnail", observable: this.mediaItemsService.downloadThumbnailImageFile(this.roomId, this.mediaItemId) });
                        }
                    },
                    error: (err) => {
                        this.router.navigate(["../"], { relativeTo: this.route });
                    },
                })
            );
        }
    }

    save() {
        if (this.roomId && this.mediaItem) {
            let newFile: File | undefined = undefined;
            if (this.selectedFile instanceof File) {
                newFile = this.selectedFile;
            }

            let newThumbnail: File | undefined = undefined;
            if (this.selectedThumbnail instanceof File) {
                newThumbnail = this.selectedThumbnail;
            }

            if (this.MODE === BaseComponentMode.ADD) {
                this.subscriptions.push(
                    this.mediaItemsService.create({ roomId: this.roomId, obj: this.mediaItem, file: newFile, thumbnail: newThumbnail }).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("mediaitems.detail.add.success"));
                        this.hideAndEmitListChanged();
                    })
                );
            }
            if (this.MODE === BaseComponentMode.EDIT) {
                this.subscriptions.push(
                    this.mediaItemsService.update({ roomId: this.roomId, obj: this.mediaItem, file: newFile, thumbnail: newThumbnail }).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("mediaitems.detail.edit.success"));
                        this.hideAndEmitListChanged();
                    })
                );
            }
        }
    }

    back() {
        this.router.navigate(["../"], { relativeTo: this.route });
    }
    hide() {
        this.modalRef?.hide();
    }

    private hideAndEmitListChanged() {
        this.modalRef?.hide();
        this.mediaItemsListChanged.next();
    }

    private downloadImageFile({ filename, imagepropertyname, observable }: { filename: string | undefined; imagepropertyname: "selectedThumbnail"; observable: Observable<Blob> }) {
        if (filename) {
            const downloadSubscription = observable.subscribe((image) => {
                const reader = new FileReader();

                reader.readAsDataURL(image);
                reader.onload = (_event) => {
                    const trustedUrl = this.sanitization.bypassSecurityTrustUrl(`${reader.result}`);
                    this[imagepropertyname] = trustedUrl;
                };
            });

            this.subscriptions.push(downloadSubscription);
        }
    }
}
