import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { MediaItemsRepository } from "./mediaitems.repository";
import { MediaItemDetailModel, MediaItemListModel } from "./viewmodels/MediaItem";
import { AdminRoomMediaItemCreateRequestDTO, AdminRoomMediaItemUpdateRequestDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IMediaItemServiceConfig {
    utcDateTimeFormat: string;
    dateTimeFormat: string;
    dateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const MediaItemServiceConfig = new InjectionToken<IMediaItemServiceConfig>("MediaItemServiceConfig");

@Injectable()
export class MediaItemsService {
    public UTCDATETIMEFORMAT: string = "";
    public DATETIMEFORMAT: string = "";
    public DATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(MediaItemServiceConfig) private mediaItemConfig: IMediaItemServiceConfig, private mediaItemsRepository: MediaItemsRepository) {
        this.UTCDATETIMEFORMAT = this.mediaItemConfig.utcDateTimeFormat;
        this.DATETIMEFORMAT = this.mediaItemConfig.dateTimeFormat;
        this.DATEFORMAT = this.mediaItemConfig.dateFormat;
        this.datatableStateSaveMode = this.mediaItemConfig.datatableStateSaveMode;
    }

    public list(roomId: string, params: ApiListQueryParameter): Observable<{ list: MediaItemListModel[]; count: number; total: number }> {
        return this.mediaItemsRepository.list(roomId, params).pipe(
            map((result) => {
                return {
                    list: result.list.map((dto) => {
                        return {
                            ...dto,
                            type: dto.roomMediaItemType,
                        };
                    }),
                    count: result.count,
                    total: result.total,
                };
            })
        );
    }

    public read(roomId: string, id: string): Observable<MediaItemDetailModel> {
        return this.mediaItemsRepository.read(roomId, id).pipe(
            map((result) => {
                return {
                    ...result,
                    type: result.roomMediaItemType,
                    file: result.file?.filename,
                    thumbnail: result.thumbnail?.filename,
                };
            })
        );
    }

    public create({ roomId, obj, file, thumbnail }: { roomId: string; obj: MediaItemDetailModel; file?: File; thumbnail?: File }): Observable<MediaItemDetailModel> {
        return this.mediaItemsRepository
            .create({
                roomId,
                dto: plainToInstance(AdminRoomMediaItemCreateRequestDTO, { ...obj, roomMediaItemType: obj.type }, { excludeExtraneousValues: true }),
                file,
                thumbnail,
            })
            .pipe(
                map((result) => {
                    return {
                        ...result,
                        type: result.roomMediaItemType,
                        file: result.file?.filename,
                        thumbnail: result.thumbnail?.filename,
                    };
                })
            );
    }

    public update({ roomId, obj, file, thumbnail }: { roomId: string; obj: MediaItemDetailModel; file?: File; thumbnail?: File }): Observable<MediaItemDetailModel> {
        if (!obj._id) {
            throw new Error("Cannot update object without _id");
        }

        return this.mediaItemsRepository.update({ roomId, id: obj._id, dto: plainToInstance(AdminRoomMediaItemUpdateRequestDTO, obj, { excludeExtraneousValues: true }), file, thumbnail }).pipe(
            map((result) => {
                return {
                    ...result,
                    type: result.roomMediaItemType,
                    file: result.file?.filename,
                    thumbnail: result.thumbnail?.filename,
                };
            })
        );
    }

    public delete(roomId: string, id: string): Observable<MediaItemDetailModel> {
        return this.mediaItemsRepository.delete(roomId, id).pipe(
            map((result) => {
                return {
                    ...result,
                    type: result.roomMediaItemType,
                    file: result.file?.filename,
                    thumbnail: result.thumbnail?.filename,
                };
            })
        );
    }

    public downloadThumbnailImageFile(roomId: string, id: string): Observable<Blob> {
        return this.mediaItemsRepository.downloadThumbnailImageFile(roomId, id);
    }
}
