import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponentMode } from "@impacgroup/angular-next-baselib";
import { RoomsService } from "./rooms.service";
import { TranslateService } from "@ngx-translate/core";
import { factoryGenerateRoomDetailModel, RoomDetailModel } from "./viewmodels/Room";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { BsModalService } from "ngx-bootstrap/modal";
import { RoomBaseDataComponent } from "./components/room-base-data/room-base-data.component";
import { CanDeactivateBaseCompoment } from "src/app/guards/pending-changes.guard";

@Component({
    selector: "app-roomdetail",
    templateUrl: "./roomdetail.component.html",
    styleUrls: ["roomdetail.component.scss"],
})
export class RoomDetailComponent extends CanDeactivateBaseCompoment {
    public roomId?: string;
    public room?: RoomDetailModel;

    public selectedBackgroundImage?: File | SafeUrl;
    public selectedMobileBackgroundImage?: File | SafeUrl;
    public selectedPreviewImage?: File | SafeUrl;

    public disableSaveButton = false;

    @ViewChild("roomBaseData", { static: false }) roomBaseDataComponent?: RoomBaseDataComponent;
    @ViewChild("roomCometChatData", { static: false }) roomCometChatDataComponent?: RoomBaseDataComponent;

    constructor(
        private route: ActivatedRoute,
        private roomsService: RoomsService,
        private router: Router,
        private alertService: AlertService,
        protected override translateService: TranslateService,
        protected override modalService: BsModalService,
        private changeDetector: ChangeDetectorRef,
        private sanitization: DomSanitizer
    ) {
        super(translateService, modalService);

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.roomId = params["id"];
                if (this.roomId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.room = factoryGenerateRoomDetailModel();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.roomId) {
            return;
        }

        const readDetailsSubscription = this.roomsService.read(this.roomId).subscribe({
            next: (result) => {
                this.room = result;

                if (this.roomId) {
                    this.downloadImageFile({ filename: this.room.backgroundImageFile, imagepropertyname: "selectedBackgroundImage", observable: this.roomsService.downloadBackgroundImageFile(this.roomId) });
                    this.downloadImageFile({ filename: this.room.mobileBackgroundImageFile, imagepropertyname: "selectedMobileBackgroundImage", observable: this.roomsService.downloadMobileBackgroundImageFile(this.roomId) });
                    this.downloadImageFile({ filename: this.room.previewImageFile, imagepropertyname: "selectedPreviewImage", observable: this.roomsService.downloadPreviewImageFile(this.roomId) });
                }
            },
            error: (err) => {
                this.router.navigate(["../"], { relativeTo: this.route });
            },
        });
        this.subscriptions.push(readDetailsSubscription);
    }

    save() {
        this.disableSaveButton = true;
        this.submitForms();

        if (this.roomBaseDataComponent?.form?.invalid || this.roomCometChatDataComponent?.form?.invalid) {
            return;
        }

        if (this.room) {
            let newBackgroundImage: File | undefined = undefined;
            if (this.selectedBackgroundImage instanceof File) {
                newBackgroundImage = this.selectedBackgroundImage;
            }

            let newMobileBackgroundImage: File | undefined = undefined;
            if (this.selectedMobileBackgroundImage instanceof File) {
                newMobileBackgroundImage = this.selectedMobileBackgroundImage;
            }

            let newPreviewImage: File | undefined = undefined;
            if (this.selectedPreviewImage instanceof File) {
                newPreviewImage = this.selectedPreviewImage;
            }

            if (this.MODE === BaseComponentMode.ADD) {
                this.subscriptions.push(
                    this.roomsService.create({ obj: this.room, backgroundImageFile: newBackgroundImage, mobileBackgroundImageFile: newMobileBackgroundImage, previewImageFile: newPreviewImage }).subscribe({
                        next: (result) => {
                            this.resetForms();
                            this.alertService.addSuccess(this.translateService.instant("rooms.detail.add.success"));
                            this.router.navigate(["../", result._id], { relativeTo: this.route });
                        },
                        error: () => {
                            this.disableSaveButton = false;
                        },
                    })
                );
            }

            if (this.MODE === BaseComponentMode.EDIT) {
                this.subscriptions.push(
                    this.roomsService.update({ obj: this.room, backgroundImageFile: newBackgroundImage, mobileBackgroundImageFile: newMobileBackgroundImage, previewImageFile: newPreviewImage }).subscribe({
                        next: (result) => {
                            this.resetForms();
                            this.alertService.addSuccess(this.translateService.instant("rooms.detail.edit.success"));
                            this.changeDetector.detectChanges();
                            this.room = result;
                        },
                        error: () => {
                            this.disableSaveButton = false;
                        },
                    })
                );
            }
        }
    }

    back() {
        this.router.navigate(["../"], { relativeTo: this.route });
    }

    public formsAreInvalid(): boolean | null | undefined {
        return this.roomBaseDataComponent?.form?.invalid || this.roomCometChatDataComponent?.form?.invalid;
    }

    protected formsAreDirty(): boolean | null | undefined {
        return this.roomBaseDataComponent?.form?.dirty || this.roomCometChatDataComponent?.form?.dirty;
    }

    private submitForms() {
        this.roomBaseDataComponent?.form?.onSubmit(new Event(""));
        this.roomCometChatDataComponent?.form?.onSubmit(new Event(""));
    }

    private resetForms() {
        this.roomBaseDataComponent?.form?.resetForm();
        this.roomCometChatDataComponent?.form?.resetForm();
    }

    private downloadImageFile({
        filename,
        imagepropertyname,
        observable,
    }: {
        filename: string | undefined;
        imagepropertyname: "selectedBackgroundImage" | "selectedMobileBackgroundImage" | "selectedPreviewImage";
        observable: Observable<Blob>;
    }) {
        if (filename) {
            const downloadSubscription = observable.subscribe((image) => {
                const reader = new FileReader();

                reader.readAsDataURL(image);
                reader.onload = (_event) => {
                    const trustedUrl = this.sanitization.bypassSecurityTrustUrl(`${reader.result}`);
                    this[imagepropertyname] = trustedUrl;
                };
            });

            this.subscriptions.push(downloadSubscription);
        }
    }
}
