import { Component, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BaseComponent, BaseComponentMode } from "@impacgroup/angular-next-baselib";
import { EventDetailModel } from "../../viewmodels/Event";

@Component({
    selector: "app-event-data",
    templateUrl: "./event-data.component.html",
    styleUrls: ["./event-data.component.scss"],
})
export class EventDataComponent extends BaseComponent {
    @ViewChild("f", { static: true }) form?: NgForm;

    @Input()
    public override MODE?: BaseComponentMode;

    @Input()
    public event?: EventDetailModel;
}
