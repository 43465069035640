import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { ContactFormType, ContactFormTypeArray } from "@impacgroup/gsk-event-platform-api-dtos";
import { RoomDataComponent } from "../room-data/room-data.component";

@Component({
    selector: "app-room-base-data",
    templateUrl: "./room-base-data.component.html",
    styleUrls: ["./room-base-data.component.scss"],
})
export class RoomBaseDataComponent extends RoomDataComponent {
    @Input() selectedBackgroundImage?: File | SafeUrl;
    @Output() selectedBackgroundImageChange = new EventEmitter<File | SafeUrl>();

    @Input() selectedMobileBackgroundImage?: File | SafeUrl;
    @Output() selectedMobileBackgroundImageChange = new EventEmitter<File | SafeUrl>();

    @Input() selectedPreviewImage?: File | SafeUrl;
    @Output() selectedPreviewImageChange = new EventEmitter<File | SafeUrl>();

    public contactFormTypes: ContactFormType[] = ContactFormTypeArray;
}
