import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { AuthService } from "@impacgroup/angular-next-oauth-base";
import { OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { BaseComponent } from "@impacgroup/angular-next-baselib";

@Component({
    selector: "app-header",
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"],
})
export class HeaderComponent extends BaseComponent {
    maCurrentTheme: string;
    maThemes: Array<string>;

    constructor(private service: AppService, private router: Router, private authService: AuthService) {
        super();

        // Retrieve current theme from AppService
        this.maCurrentTheme = service.maTheme;

        // Available themes
        this.maThemes = ["green", "blue", "red", "teal", "cyan", "blue-grey", "purple", "indigo"];
    }

    // Set theme
    setMaTheme() {
        this.service.maTheme = this.maCurrentTheme;
    }

    public logout() {
        this.subscriptions.push(
            this.authService.logout().subscribe((result) => {
                OAuthStorageAdapter.clearStorage();
                this.router.navigate(["/login"]);
            })
        );
    }
}
