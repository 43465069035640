import { Directive, HostListener, Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { BaseComponent, ConfirmDialogComponent } from "@impacgroup/angular-next-baselib";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";

@Directive()
export abstract class CanDeactivateBaseCompoment extends BaseComponent implements CanComponentDeactivate {
    constructor(protected translateService: TranslateService, protected modalService: BsModalService) {
        super();
    }

    @HostListener("window:beforeunload")
    beforeUnload() {
        if (this.formsAreDirty()) {
            return confirm(this.translateService.instant("global.dialogs.pedingUnsavedChanges.message"));
        } else {
            return true;
        }
    }

    canDeactivate(): Observable<boolean> | true | boolean {
        const resultSubject = new Subject<boolean>();

        if (this.formsAreDirty()) {
            const initialState = {
                title: this.translateService.instant("global.dialogs.pedingUnsavedChanges.title"),
                message: this.translateService.instant("global.dialogs.pedingUnsavedChanges.message"),
                closeBtnName: this.translateService.instant("global.buttons.close"),
                confirmBtnName: this.translateService.instant("global.buttons.ok"),
            };
            const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
            if (bsModalRef.content) {
                bsModalRef.content.confirmCallback = () => {
                    resultSubject.next(true);
                };
            }
            return resultSubject;
        } else {
            return true;
        }
    }

    protected abstract formsAreDirty(): boolean | null | undefined;
}

export interface CanComponentDeactivate {
    // Must be used with annotation: @HostListener("window:beforeunload")
    beforeUnload: () => boolean;

    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
