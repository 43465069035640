<div class="content__inner" *appHasPermission="'EVENTS.EDIT'">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "events.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "events.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <tabset *ngIf="event">
        <tab>
            <app-event-base-data #eventBaseData [MODE]="MODE" [event]="event" [(selectedBackgroundImage)]="selectedBackgroundImage"></app-event-base-data>
        </tab>

        <tab>
            <app-event-meetps #eventMeetPS [MODE]="MODE" [event]="event"></app-event-meetps>
        </tab>

        <tab>
            <app-event-available-rooms #eventAvailableRooms [event]="event"></app-event-available-rooms>
        </tab>

        <tab>
            <app-event-participant-mails #eventParticipantMails [MODE]="MODE" [event]="event"></app-event-participant-mails>
        </tab>
    </tabset>

    <div class="col-md-12">
        <button type="button" (click)="save()" class="float-right btn btn-success btn--icon-text" [disabled]="disableSaveButton && formsAreInvalid()"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
    </div>
</div>
