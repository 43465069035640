import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FileUploadComponent } from "./file-upload/file-upload.component";

@NgModule({
    declarations: [FileUploadComponent],
    imports: [CommonModule, FormsModule, TranslateModule],
    exports: [FileUploadComponent],
})
export class AdminComponentsModule {}
