<div *appHasPermission="'EVENTS.EDIT'">
    <div class="modal-header">
        <h4>{{ "events.detail.participantMails.import.title" | translate }}</h4>
    </div>

    <form class="modal-body row" name="form" (ngSubmit)="f.form.valid && import()" #f="ngForm" novalidate autocomplete="off">
        <div class="col-md-12">
            <app-file-upload
                class="d-block mb-2"
                i18nKey="events.detail.participantMails.import.file"
                name="file"
                [(ngModel)]="csvFilename"
                [file]="selectedFile"
                (fileChange)="validateCSV($event)"
                [allowedExtensions]="'csv'"
                [submitted]="f.submitted"
            ></app-file-upload>

            <div [ngSwitch]="fileProcessStatus">
                <div *ngSwitchCase="FileProcessStatus.uploadAborted">{{ "events.detail.participantMails.import.uploadAborted" | translate }}</div>
                <div *ngSwitchCase="FileProcessStatus.uploadError">{{ "events.detail.participantMails.import.uploadError" | translate }}</div>
                <div *ngSwitchCase="FileProcessStatus.uploading">{{ "events.detail.participantMails.import.uploading" | translate: { progress: progress } }}</div>
                <div *ngSwitchCase="FileProcessStatus.processing">{{ "events.detail.participantMails.import.processing" | translate }}</div>
                <div *ngSwitchCase="FileProcessStatus.finished">
                    <div>{{ "events.detail.participantMails.import.finished.validMailCount" | translate: { count: validMails.length } }}</div>
                    <div>{{ "events.detail.participantMails.import.finished.invalidMailCount" | translate: { count: invalidMails.length } }}</div>
                    <div *ngIf="invalidMailList !== ''">
                        {{ "events.detail.participantMails.import.finished.invalidMails" | translate }}
                        {{ invalidMailList }}

                        <div class="text-danger">{{ "events.detail.participantMails.import.finished.proceedWarning" | translate }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <button class="float-right btn btn-success btn--icon-text ml-2" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.add" | translate }}</button>
            <button type="button" (click)="hide()" class="float-right btn btn-secondary btn--icon-text"><i class="zmdi zmdi-check"></i> {{ "global.buttons.close" | translate }}</button>
        </div>
    </form>
</div>
