import { ContactFormType } from "@impacgroup/gsk-event-platform-api-dtos";

export class RoomListModel {
    _id?: string;

    name?: string;
    videoId?: string;

    created?: Date;
    createdBy?: {
        id?: string;
        email?: string;
    };

    updated?: Date;
    updatedBy?: {
        id?: string;
        email?: string;
    };
}

export class RoomDetailModel {
    _id?: string;

    name?: string;
    videoId?: string;
    adboardLink?: string;
    backgroundImageFile?: string;
    mobileBackgroundImageFile?: string;
    previewImageFile?: string;
    contactForm?: ContactFormType;

    cometChat?: {
        active?: boolean;
        appId?: string;
        appRegion?: string;
        authKey?: string;
        widgetId?: string;
        docked?: boolean;
        alignment?: string;
        roundedCorners?: boolean;
    };

    version?: number;
}

export const factoryGenerateRoomDetailModel = (): RoomDetailModel => {
    return {
        name: "",
        videoId: "",
        adboardLink: "",
        backgroundImageFile: "",
        mobileBackgroundImageFile: "",
        previewImageFile: "",
        contactForm: "Gynäkoonkologie",

        cometChat: {
            active: false,
            appId: "",
            appRegion: "",
            authKey: "",
            widgetId: "",
            docked: false,
            alignment: "",
            roundedCorners: false,
        },
    };
};
