import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-next-baselib";
import { PermissionService } from "@impacgroup/angular-next-oauth-protection";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EventDetailModel } from "../../viewmodels/Event";

@Component({
    selector: "app-event-participant-mails",
    templateUrl: "./event-participant-mails.component.html",
    styleUrls: ["./event-participant-mails.component.scss"],
})
export class EventParticipantMailsComponent implements OnInit {
    @Input()
    public event?: EventDetailModel;

    @Input()
    public MODE?: BaseComponentMode;

    @ViewChild("patricipantMailAddModalTemplate")
    public patricipantMailAddModalTemplate?: TemplateRef<any>;
    public patricipantMailAddModalRef?: BsModalRef;

    @ViewChild("patricipantMailImportModalTemplate")
    public patricipantMailImportModalTemplate?: TemplateRef<any>;
    public patricipantMailImportModalRef?: BsModalRef;

    public dirty = false;

    public permissionGranted = false;

    constructor(private modalService: BsModalService, private translateService: TranslateService, private permissionService: PermissionService) {}

    ngOnInit(): void {
        if (this.MODE === BaseComponentMode.ADD) {
            this.permissionGranted = this.permissionService.hasPermission("EVENTS.ADD");
        } else {
            this.permissionGranted = this.permissionService.hasPermission("EVENTS.EDIT");
        }
    }

    public openAddParticipantMailModal() {
        if (this.patricipantMailAddModalTemplate) {
            this.patricipantMailAddModalRef = this.modalService.show(this.patricipantMailAddModalTemplate, { class: "modal-lg" });
        }
    }

    public openAddParticipantMailsByCSVModal() {
        if (this.patricipantMailImportModalTemplate) {
            this.patricipantMailImportModalRef = this.modalService.show(this.patricipantMailImportModalTemplate, { class: "modal-lg" });
        }
    }

    public addParticipantMail(mail: string) {
        if (!this.event) {
            return;
        }

        this.dirty = true;

        if (!this.event.participantMails) {
            this.event.participantMails = [];
        }

        if (!this.event.participantMails.includes(mail)) {
            this.event.participantMails.push(mail);
        }
    }

    public addParticipantMails(mails: string[]) {
        mails.map((mail) => {
            this.addParticipantMail(mail);
        });
    }

    public deletePartifipantMail(mail: string) {
        const initialState = {
            title: this.translateService.instant("events.detail.participantMails.delete.title"),
            message: this.translateService.instant("events.detail.participantMails.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok"),
        };

        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (!this.event || !mail || !this.event.participantMails) {
                    return;
                }
                this.dirty = true;

                const parMailIdx = this.event.participantMails.indexOf(mail);
                if (parMailIdx > -1) {
                    this.event.participantMails.splice(parMailIdx, 1);
                }
            };
        }
    }
}
