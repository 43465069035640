import { Component, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { PermissionService, OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { Router } from "@angular/router";
import { BaseComponent } from "@impacgroup/angular-next-baselib";
import { AuthService } from "@impacgroup/angular-next-oauth-base";
import { PermissionsConfig } from "src/app/config/PermissionsConfig";

type MenuItem = {
    title: string;
    icon?: string;
    permission?: string;
    route?: string;
    visibility?: string;
    sub?: MenuItem[];
};

@Component({
    selector: "app-sidebar",
    templateUrl: "sidebar.component.html",
    styleUrls: ["sidebar.component.scss"],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: "sidebar",
    },
    animations: [
        trigger("toggleSubMenu", [
            state(
                "inactive",
                style({
                    height: "0",
                    opacity: "0",
                })
            ),
            state(
                "active",
                style({
                    height: "*",
                    opacity: "1",
                })
            ),
            transition("inactive => active", animate("200ms ease-in")),
            transition("active => inactive", animate("200ms ease-out")),
        ]),
    ],
})
export class SidebarComponent extends BaseComponent implements AfterViewInit {
    /* Main Menu

     * title: Main menu title
     * icon: Menu icon from material-design-iconic-fonts. Please refer "Icons" page for more details
     * route: Router link for page
     * sub: Sub menus
     * visibility: Property for animation. "inactive" means the sub menu is hidden by default.

     */
    mainMenu: MenuItem[] = [];

    tokenExpires: Date = new Date();

    // Toggle sub menu
    toggleSubMenu(i: number) {
        this.mainMenu[i].visibility = this.mainMenu[i].visibility === "inactive" ? "active" : "inactive";
    }

    constructor(private translate: TranslateService, private permissionService: PermissionService, private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private authService: AuthService) {
        super();
        this.mainMenu = [
            {
                title: this.translate.instant("menu.home"),
                icon: "home",
                route: "/home",
                permission: "AUTHENTICATED",
            },
            {
                title: this.translate.instant("menu.user.title"),
                icon: "accounts-alt",
                permission: "MENU.USERMANAGEMENT",
                sub: [
                    {
                        title: this.translate.instant("menu.user.users"),
                        route: "/usermanagement/users",
                        permission: "MENU.USERMANAGEMENT.USERS",
                    },
                    {
                        title: this.translate.instant("menu.user.roles"),
                        route: "/usermanagement/roles",
                        permission: "MENU.USERMANAGEMENT.ROLES",
                    },
                    {
                        title: this.translate.instant("menu.user.organizations"),
                        route: "/usermanagement/organizations",
                        permission: "MENU.USERMANAGEMENT.ORGANIZATIONS",
                    },
                ],
                visibility: "inactive",
            },
            {
                title: this.translate.instant("menu.rooms"),
                icon: "folder",
                route: "/rooms",
                permission: "MENU.ROOMS",
                visibility: "inactive",
            },
            {
                title: this.translate.instant("menu.events"),
                icon: "calendar",
                route: "/events",
                permission: "MENU.EVENTS",
                visibility: "inactive",
            },
            {
                title: this.translate.instant("menu.participantaccess"),
                icon: "shield-check",
                route: "/participantaccess",
                permission: "MENU.PARTICIPANTACCESS",
                visibility: "inactive",
            },
            /*
            {
                title: this.translate.instant("menu.invitations"),
                icon: "account-add",
                route: "/invitations",
                permission: "MENU.INVITATIONS",
                visibility: "inactive",
            },
            */
            {
                title: this.translate.instant("menu.languages"),
                icon: "flag",
                route: "/languages",
                permission: "MENU.LANGUAGES",
                visibility: "inactive",
            },
            {
                title: this.translate.instant("menu.mail.title"),
                icon: "email",
                permission: "MENU.MAIL",
                sub: [
                    {
                        title: this.translate.instant("menu.mail.mailaccounts"),
                        route: "/mail/mailaccounts",
                        permission: "MENU.MAIL.MAILACCOUNTS",
                    },
                    {
                        title: this.translate.instant("menu.mail.mailtemplates"),
                        route: "/mail/mailtemplates",
                        permission: "MENU.MAIL.MAILTEMPLATES",
                    },
                ],
                visibility: "inactive",
            },
            {
                title: this.translate.instant("menu.registration"),
                icon: "settings",
                route: "/registrationconfig",
                permission: "MENU.REGISTRATION",
                visibility: "inactive",
            },
        ];
        this.filterByPermissions();
        this.updateTokenExpirationInfo();
    }

    private filterByPermissions(): void {
        const filteredMenu = this.mainMenu.filter((menu) => {
            if (menu.sub && menu.sub.length > 0) {
                const filteredSubmenu = menu.sub.filter((sub) => this.permissionService.hasPermission(sub.permission ?? ""));
                menu.sub = filteredSubmenu;
            }
            return this.permissionService.hasPermission(menu.permission ?? "");
        });
        this.mainMenu = filteredMenu;
    }

    private updateTokenExpirationInfo(): void {
        this.tokenExpires = new Date((this.oauthStorageAdapter.getJWTPayload()?.exp ?? 0) * 1000);
    }

    ngAfterViewInit() {
        this.mainMenu.forEach((mainMenuNode) => {
            if (mainMenuNode.sub) {
                mainMenuNode.sub.forEach((subMenuNode) => {
                    if (this.router.isActive(subMenuNode.route ?? "", false)) {
                        setTimeout(() => {
                            mainMenuNode.visibility = "active";
                        }, 0);
                    }
                });
            }
        });
    }

    public refreshToken() {
        this.subscriptions.push(
            this.authService.refresh().subscribe(() => {
                this.permissionService.updatePermissions(PermissionsConfig);

                this.filterByPermissions();
                this.updateTokenExpirationInfo();
            })
        );
    }
}
