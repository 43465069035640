import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { ParticipantAccessComponent } from "./participantaccess.component";
import { ParticipantAccessServiceConfig, ParticipantAccessService, IParticipantAccessServiceConfig } from "./participantaccess.service";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";
import { OAuthProtectionModule } from "@impacgroup/angular-next-oauth-protection";
import { Route, RouterModule } from "@angular/router";
import { ParticipantAccessMainComponent } from "./participantaccess.main.component";
import { ParticipantAccessRepository, IParticipantAccessRepositoryConfig, ParticipantAccessRepositoryConfig } from "./participantaccess.repository";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AdminComponentsModule } from "src/app/components/admin-components.module";

@NgModule({
    declarations: [ParticipantAccessMainComponent, ParticipantAccessComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DataTablesModule,
        BsDropdownModule,
        ModalModule,
        NgSelectModule,
        TranslateModule,
        OAuthProtectionModule,
        QuillModule,
        BsDatepickerModule,
        TimepickerModule,
        TabsModule,
        AdminComponentsModule,
    ],
    exports: [],
    providers: [],
})
export class ParticipantAccesssModule {
    static forRoot(config: IParticipantAccessServiceConfig & IParticipantAccessRepositoryConfig): ModuleWithProviders<ParticipantAccesssModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ParticipantAccesssModule,
            providers: [
                ParticipantAccessService,
                {
                    provide: ParticipantAccessServiceConfig,
                    useValue: config,
                },
                ParticipantAccessRepository,
                {
                    provide: ParticipantAccessRepositoryConfig,
                    useValue: config,
                },
            ],
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: ParticipantAccessMainComponent,
                children: [
                    {
                        path: "",
                        component: ParticipantAccessComponent,
                    },
                ],
            },
        ];
    }
}
