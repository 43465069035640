<div class="file-upload-container" (drag)="allowDrop($event)" (drop)="onDrop($event)" (dragover)="onDragEnter($event)" (dragleave)="onDragLeave($event)" [ngClass]="{ dragover: showDropPlaces }">
    <div class="form-group">
        <label>{{ i18nKey + ".label" | translate }}</label>
        <input type="text" class="form-control" [(ngModel)]="value" #uploadFile="ngModel" [ngClass]="{ 'is-invalid': submitted && uploadFile.invalid && value !== '' }" readonly />
        <i class="form-group__bar"></i>
        <div class="invalid-tooltip">
            {{ i18nKey + ".invalid" | translate }}
        </div>
    </div>

    <div class="preview-image-container mb-4" *ngIf="showPreview">
        <div class="preview-image">
            <img *ngIf="selectedImage" [src]="selectedImage" />
        </div>
    </div>
</div>

<div class="file-upload-button-container" (drag)="allowDrop($event)" (drop)="onDrop($event)" (dragover)="onDragEnter($event)" (dragleave)="onDragLeave($event)" [ngClass]="{ dragover: showDropPlaces }">
    <button (click)="fileInput.click()" class="btn btn-success btn--icon-text" type="button"><i class="zmdi zmdi-collection-plus"></i> {{ i18nKey + ".button" | translate }}</button>
    <input #fileInput type="file" (change)="chooseFile($event)" [attr.accept]="acceptExtensions" />
</div>
