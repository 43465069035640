import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ParticipantAccessService } from "./participantaccess.service";
import { BaseDatatableComponent, DataTableParameters } from "@impacgroup/angular-next-baselib";
import { ParticipantAccessListModel } from "./viewmodels/ParticipantAccess";
import { ParticipantAccessMainComponent } from "./participantaccess.main.component";

@Component({
    selector: "app-participantaccess",
    templateUrl: "./participantaccess.component.html",
    styleUrls: ["participantaccess.component.scss"],
})
export class ParticipantAccessComponent extends BaseDatatableComponent {
    public UTCDATETIMEFORMAT: string = "";
    public DATETIMEFORMAT: string = "";

    rows: ParticipantAccessListModel[] = [];

    constructor(private participantAccessService: ParticipantAccessService, private translateService: TranslateService, parent: ParticipantAccessMainComponent) {
        super(translateService, {
            stateSaveMode: participantAccessService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_participantaccess_list",
        });

        this.dtOptions.order = [[3, "desc"]];
        this.UTCDATETIMEFORMAT = this.participantAccessService.UTCDATETIMEFORMAT;
        this.DATETIMEFORMAT = this.participantAccessService.DATETIMEFORMAT;
    }

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback: Function) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.participantAccessService.list(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: [],
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "email", orderable: false },
            { data: "event", orderable: false },
            { data: "token", orderable: false },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
        ];
    }
}
