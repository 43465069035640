import * as moment from "moment";

export const convertDateToZeroOClock = (input: Date): Date => {
    return moment(moment(input).format("YYYYMMDD") + "-000000", "YYYYMMDD-hhmmss").toDate();
};

export const roundTo5Minutes = (date: Date) => {
    date.setMinutes(Math.round(date.getMinutes() / 5) * 5);
    date.setSeconds(0);
    date.setMilliseconds(0);
};
