export class EventListModel {
    _id?: string;

    name?: string;
    topic?: string;
    fromDate?: Date;
    toDate?: Date;

    created?: Date;
    createdBy?: {
        id?: string;
        email?: string;
    };

    updated?: Date;
    updatedBy?: {
        id?: string;
        email?: string;
    };
}

export type EventDetailModel = {
    _id?: string;

    name?: string;
    topic?: string;
    fromDate?: Date;
    toDate?: Date;
    videoId?: string;
    backgroundImageFile?: string;
    availableRooms?: EventDetailAvailableRoomModel[];
    participantMails?: string[];

    meetPS: {
        enabled?: boolean;
        userLogin?: boolean;
        url?: string;
        client?: string;
        secret?: string;
    };

    version?: number;
};

export class EventDetailAvailableRoomModel {
    _id?: string;
    name?: string;
    assigned?: boolean;
}

export const factoryGenerateEventDetailModel = (): EventDetailModel => {
    return {
        name: "",
        topic: "",
        fromDate: undefined,
        toDate: undefined,
        videoId: "",
        backgroundImageFile: "",
        availableRooms: [],
        participantMails: [],
        meetPS: {
            enabled: false,
            userLogin: false,
            url: "",
            client: "",
            secret: "",
        },
    };
};
