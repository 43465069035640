import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Injector } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppService } from "./app.service";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LogoComponent } from "./layout/header/logo/logo.component";
import { NavigationTriggerComponent } from "./layout/header/navigation-trigger/navigation-trigger.component";
import { UserComponent } from "./layout/sidebar/user/user.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";

import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AppRoutingModule } from "./app-routing.module";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { OAuthModule } from "@impacgroup/angular-next-oauth-base";
import { PermissionsConfig } from "./config/PermissionsConfig";
import { environment } from "../environments/environment";
import { AngularBaseModule, BaseDatatableStateSaveMode } from "@impacgroup/angular-next-baselib";
import { HttpErrorInterceptor } from "./global/api-handling/HttpErrorInterceptor";
import { FormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-next-access-management";
import { LOCATION_INITIALIZED } from "@angular/common";
import { HttpLoadingService } from "./global/api-handling/HttpLoadingService";
import { LanguagesModule, LanguagesService } from "@impacgroup/angular-next-languages";
import { MailTemplatesModule, SmtpMailAccountsModule, MailTemplateLanguagesConfig } from "@impacgroup/angular-next-mailtemplates";
import { QuillModule } from "ngx-quill";
import { StandardCountryService } from "./global/services/country.service";
import { OAuthProtectionModule } from "@impacgroup/angular-next-oauth-protection";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-next-selfservice";
import { AcceptInvitationsModule, InvitationsModule } from "@impacgroup/angular-next-invitations";
import { RegistrationConfigModule } from "@impacgroup/angular-next-registration";
import { RoomsModule } from "./modules/rooms/rooms.module";
import { EventsModule } from "./modules/events/events.module";
import { ParticipantAccesssModule } from "./modules/participantaccess/participantaccess.module";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise((resolve) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                translate.addLangs(["en"]);

                const langToSet = "en";
                translate.setDefaultLang("en");
                translate.use(langToSet).subscribe(
                    () => {
                        // console.log(`Successfully initialized '${langToSet}' language.'`);
                    },
                    (err) => {
                        // eslint-disable-next-line no-console
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
@NgModule({
    declarations: [AppComponent, SidebarComponent, HeaderComponent, FooterComponent, FullLayoutComponent, SingleLayoutComponent, LogoComponent, NavigationTriggerComponent, UserComponent, PageLoaderComponent],
    imports: [
        EventsModule.forRoot({
            eventsAPI: environment.eventsAPI,
            utcDateTimeFormat: environment.utcDateTimeFormat,
            dateTimeFormat: environment.dateTimeFormat,
            dateFormat: environment.dateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        AngularBaseModule.forRoot(environment.httpRequestTimeoutSeconds),
        OAuthProtectionModule.forRoot({
            PERMISSIONS: PermissionsConfig.PERMISSIONS,
        }),
        OAuthModule.forRoot(environment.oauthBaseAPI),
        OrganizationsModule.forRoot({
            organizationsAPI: environment.organizationsAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        RolesModule.forRoot({
            rolesAPI: environment.rolesAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        UsersModule.forRoot({
            usersAPI: environment.usersAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        LanguagesModule.forRoot({
            languagesAPI: environment.languagesAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        SmtpMailAccountsModule.forRoot({
            smtpMailAccountsAPI: environment.smtpMailAccountsAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        MailTemplatesModule.forRoot({
            mailtemplatesAPI: environment.mailtemplatesAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        ProfileModule.forRoot({
            profileAPI: environment.profileAPI,
        }),
        ResetPasswordModule.forRoot({
            resetPasswordAPI: environment.resetPasswordAPI,
        }),
        InvitationsModule.forRoot({
            invitationsAPI: environment.invitationsAPI,
            utcDateFormat: environment.utcDateTimeFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        AcceptInvitationsModule.forRoot({
            acceptInvitationsAPI: environment.acceptInvitationsAPI,
        }),
        RegistrationConfigModule.forRoot({
            registrationConfigAPI: environment.registrationConfigAPI,
        }),
        RoomsModule.forRoot({
            roomsAPI: environment.roomsAPI,
            utcDateTimeFormat: environment.utcDateTimeFormat,
            dateTimeFormat: environment.dateTimeFormat,
            dateFormat: environment.dateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        ParticipantAccesssModule.forRoot({
            participantAccessAPI: environment.participantAccessAPI,
            utcDateTimeFormat: environment.utcDateTimeFormat,
            dateTimeFormat: environment.dateTimeFormat,
            dateFormat: environment.dateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
        }),
        AlertModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        NgScrollbarModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TranslateModule.forRoot(),
        NgSelectModule,
        HttpClientModule,
        QuillModule.forRoot(),
        BsDatepickerModule.forRoot(),
    ],
    providers: [
        AppService,
        StandardCountryService,
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient],
        },
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        {
            provide: MailTemplateLanguagesConfig,
            useClass: LanguagesService,
        },
        HttpLoadingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
