import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { ApiListQueryParameter } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { AdminRoomMediaItemCreateRequestDTO, AdminRoomMediaItemDetailResponseDTO, AdminRoomMediaItemListResponseDTO, AdminRoomMediaItemUpdateRequestDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IMediaItemRepositoryConfig {
    roomsAPI: string;
}

export const MediaItemRepositoryConfig = new InjectionToken<IMediaItemRepositoryConfig>("MediaItemRepositoryConfig");

@Injectable()
export class MediaItemsRepository {
    constructor(@Inject(MediaItemRepositoryConfig) private mediaItemRepositoryConfig: IMediaItemRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public list(roomId: string, params: ApiListQueryParameter): Observable<{ list: AdminRoomMediaItemListResponseDTO[]; count: number; total: number }> {
        return this.http
            .get<Object[]>(this.getServiceUrl(roomId, ""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params,
                observe: "response",
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(AdminRoomMediaItemListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total,
                    };
                })
            );
    }

    public read(roomId: string, id: string): Observable<AdminRoomMediaItemDetailResponseDTO> {
        return this.http
            .get<Object>(this.getServiceUrl(roomId, id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminRoomMediaItemDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public create({ roomId, dto, file, thumbnail }: { roomId: string; dto: AdminRoomMediaItemCreateRequestDTO; file?: File; thumbnail?: File }): Observable<AdminRoomMediaItemDetailResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(roomId, ""), this.objectAndFileToFormData({ dto, file, thumbnail }), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                observe: "response",
            })
            .pipe(map((result) => plainToInstance(AdminRoomMediaItemDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public update({ roomId, id, dto, file, thumbnail }: { roomId: string; id: string; dto: AdminRoomMediaItemUpdateRequestDTO; file?: File; thumbnail?: File }): Observable<AdminRoomMediaItemDetailResponseDTO> {
        return this.http
            .patch<Object>(this.getServiceUrl(roomId, id), this.objectAndFileToFormData({ dto, file, thumbnail }), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                observe: "response",
            })
            .pipe(map((result) => plainToInstance(AdminRoomMediaItemDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public delete(roomId: string, id: string): Observable<AdminRoomMediaItemDetailResponseDTO> {
        return this.http
            .delete<Object>(this.getServiceUrl(roomId, id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminRoomMediaItemDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public downloadThumbnailImageFile(roomId: string, id: string) {
        return this.http.get(this.getServiceUrl(roomId, id + "/thumbnail"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob",
        });
    }

    private objectAndFileToFormData({ dto, file, thumbnail }: { dto: AdminRoomMediaItemCreateRequestDTO | AdminRoomMediaItemUpdateRequestDTO; file?: File; thumbnail?: File }) {
        const formData = new FormData();
        formData.append("dto", JSON.stringify(instanceToPlain(dto, { excludeExtraneousValues: true })));

        if (file) {
            formData.append("file", file, file.name);
        }

        if (thumbnail) {
            formData.append("thumbnail", thumbnail, thumbnail.name);
        }

        return formData;
    }

    private getServiceUrl(roomId: string, postfix: string): string {
        return `${this.mediaItemRepositoryConfig.roomsAPI}${roomId}/mediaitems/${postfix}`;
    }
}
