import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-next-baselib";

@Component({
    selector: "app-participantaccess-main",
    templateUrl: "./participantaccess.main.component.html",
})
export class ParticipantAccessMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}
