import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "app-event-participant-mail-add",
    templateUrl: "./event-participant-mail-add.component.html",
    styleUrls: ["./event-participant-mail-add.component.scss"],
})
export class EventParticipantMailAddComponent {
    @Input()
    public modalRef?: BsModalRef;

    public mail: string = "";

    @Output()
    public newParticipantMail = new EventEmitter<string>();

    add() {
        this.newParticipantMail.emit(this.mail);
        this.modalRef?.hide();
    }

    hide() {
        this.modalRef?.hide();
    }
}
