import { Component, EventEmitter, Input, OnChanges, Optional, Output, Self, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";
import { SafeUrl } from "@angular/platform-browser";
import { AlertService } from "@impacgroup/angular-next-baselib";
import { TranslateService } from "@ngx-translate/core";
import { AbstractValueAccessor } from "./abstract-value-accessor";

@Component({
    selector: "app-file-upload",
    templateUrl: "./file-upload.component.html",
    styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent extends AbstractValueAccessor implements OnChanges {
    @Input()
    i18nKey: string = "";

    @Input()
    name?: string;

    @Input()
    allowedExtensions?: string;

    acceptExtensions?: string;

    @Input()
    showPreview?: boolean;

    @Output() valueChanged = new EventEmitter<string>();

    @Input() file?: File | SafeUrl | null;
    @Output() fileChange = new EventEmitter<File | SafeUrl>();

    @Input()
    submitted?: boolean;

    public showDropPlaces = false;

    public selectedImage?: string | ArrayBuffer | null;

    constructor(private alertService: AlertService, private translate: TranslateService, @Optional() @Self() public ngControl?: NgControl) {
        super();

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.showPreview && changes["file"] && !(changes["file"].currentValue instanceof File)) {
            this.selectedImage = changes["file"].currentValue;
        }

        if (changes["allowedExtensions"]?.currentValue) {
            const allowedExt: string = changes["allowedExtensions"]?.currentValue;
            if (allowedExt) {
                this.acceptExtensions = allowedExt
                    .split("|")
                    .map((ext) => `.${ext} `)
                    .join(",");
            } else {
                this.acceptExtensions = allowedExt;
            }
        }
    }

    onDrop(ev: DragEvent) {
        ev.preventDefault();
        this.showDropPlaces = false;
        this.processFiles(ev.dataTransfer?.files);
    }

    allowDrop(ev: DragEvent) {
        ev.preventDefault();
    }

    onDragEnter(ev: DragEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.showDropPlaces = true;
    }

    onDragLeave(ev: DragEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.showDropPlaces = false;
    }

    chooseFile(ev: Event) {
        const eventTarget = ev.target as HTMLInputElement | null;

        this.processFiles(eventTarget?.files);
    }

    processFiles(files: FileList | undefined | null) {
        if (!files || files.length === 0) {
            return;
        }

        const file = files[0];

        if (file) {
            const fileExtRegExp = new RegExp(`(${this.allowedExtensions})\/*`);
            const mimeType = file.type;

            if (this.allowedExtensions && this.allowedExtensions !== "" && mimeType.match(fileExtRegExp) == null) {
                const translatedOrText = this.translate.instant("global.validation.or");

                const allowedExtensionsText = this.allowedExtensions
                    .replace(/(\|)(?!.*\1)/, ` ${translatedOrText} `) // replace last pipe occurence
                    .split("|")
                    .join(", ");

                this.alertService.addCustomErrorByI18nId("global.validation.upload_type_invalid", { filetype: allowedExtensionsText });
                return;
            }
        }

        this.file = file;
        this.fileChange.emit(file);

        if (this.file instanceof File) {
            this.value = this.file.name;

            if (this.showPreview) {
                const reader = new FileReader();

                reader.readAsDataURL(this.file);
                reader.onload = (_event) => {
                    this.selectedImage = reader.result;
                };
            }
        }
    }
}
