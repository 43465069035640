import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";
import { AuthenticationGuard } from "@impacgroup/angular-next-oauth-protection";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-next-access-management";
import { LanguagesModule } from "@impacgroup/angular-next-languages";
import { MailTemplatesModule, SmtpMailAccountsModule } from "@impacgroup/angular-next-mailtemplates";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-next-selfservice";
import { AcceptInvitationsModule, InvitationsModule } from "@impacgroup/angular-next-invitations";
import { RegistrationConfigModule } from "@impacgroup/angular-next-registration";
import { RoomsModule } from "./modules/rooms/rooms.module";
import { EventsModule } from "./modules/events/events.module";
import { ParticipantAccesssModule } from "./modules/participantaccess/participantaccess.module";

const routes: Routes = [
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        children: [
            {
                path: "events",
                children: EventsModule.getRoutes(),
            },
            // Home
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full",
            },
            {
                path: "home",
                loadChildren: () => import("./pages/home/home.module").then((m) => m.HomeModule),
            },
            {
                path: "usermanagement",
                children: [
                    {
                        path: "",
                        redirectTo: "users",
                        pathMatch: "full",
                    },
                    {
                        path: "users",
                        children: UsersModule.getRoutes(),
                    },
                    {
                        path: "roles",
                        children: RolesModule.getRoutes(),
                    },
                    {
                        path: "organizations",
                        children: OrganizationsModule.getRoutes(),
                    },
                ],
            },
            {
                path: "rooms",
                children: RoomsModule.getRoutes(),
            },
            /*
            {
                path: "invitations",
                children: InvitationsModule.getRoutes(),
            },
            */
            {
                path: "participantaccess",
                children: ParticipantAccesssModule.getRoutes(),
            },
            {
                path: "profile",
                children: ProfileModule.getRoutes(),
            },
            {
                path: "languages",
                children: LanguagesModule.getRoutes(),
            },
            {
                path: "mail",
                children: [
                    {
                        path: "",
                        redirectTo: "mailaccounts",
                        pathMatch: "full",
                    },
                    {
                        path: "mailaccounts",
                        children: SmtpMailAccountsModule.getRoutes(),
                    },
                    {
                        path: "mailtemplates",
                        children: MailTemplatesModule.getRoutes(),
                    },
                ],
            },
            {
                path: "registrationconfig",
                children: RegistrationConfigModule.getRoutes(),
            },
        ],
    },
    {
        path: "",
        component: SingleLayoutComponent,
        children: [
            {
                path: "login",
                loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
            },
            {
                path: "acceptinvitation",
                children: AcceptInvitationsModule.getRoutes(),
            },
            {
                path: "resetpassword",
                children: ResetPasswordModule.getRoutes(),
            },
            {
                path: "404",
                loadChildren: () => import("./pages/four-zero-four/four-zero-four.module").then((m) => m.FourZeroFourModule),
            },
            {
                path: "**",
                redirectTo: "/404",
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
