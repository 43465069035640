<form name="form" #f="ngForm" novalidate autocomplete="off">
    <ng-container *ngIf="event">
        <ng-template tabHeading>
            {{ "events.detail.tabs.availableRooms" | translate }}<i class="ml-2 zmdi zmdi-circle text-transparent" [ngClass]="{ 'text-primary': f.dirty && (f.valid || !f.submitted), 'text-red': f.dirty && f.invalid && f.submitted }"></i>
        </ng-template>

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ "events.detail.availableRooms.title" | translate }}</h4>
                <h6 class="card-subtitle">{{ "events.detail.availableRooms.subtitle" | translate }}</h6>

                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th id="change" class="assigned-column">{{ "events.detail.availableRooms.table.assigned" | translate }}</th>
                            <th id="name">{{ "events.detail.availableRooms.table.name" | translate }}</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="event.availableRooms && event.availableRooms?.length !== 0">
                        <tr *ngFor="let room of event.availableRooms; let i = index">
                            <td>
                                <div class="checkbox text-center">
                                    <input type="checkbox" id="availableRooms_{{ i }}" name="availableRooms_{{ i }}" [(ngModel)]="room.assigned" (change)="changeRoomCheckbox($event, room)" />
                                    <label class="checkbox__label" for="availableRooms_{{ i }}"></label>
                                </div>
                            </td>
                            <td>{{ room.name }}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!event.availableRooms || event.availableRooms?.length === 0">
                        <tr>
                            <td colspan="2" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                        </tr>
                    </tbody>

                    <tbody></tbody>
                </table>
            </div>
        </div>
    </ng-container>
</form>
