import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { isEmail } from "class-validator";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialogComponent } from "@impacgroup/angular-next-baselib";

// export type FileProcessStatus = "none" | "uploadAborted" | "uploadError" | "uploading" | "processing";
// export const FileProcessStatusArray: FileProcessStatus[] = ["none", "uploadAborted", "uploadError", "uploading", "processing"];

export enum FileProcessStatus {
    none = "none",
    uploadAborted = "uploadAborted",
    uploadError = "uploadError",
    uploading = "uploading",
    processing = "processing",
    finished = "finished",
}

@Component({
    selector: "app-event-participant-mail-import",
    templateUrl: "./event-participant-mail-import.component.html",
    styleUrls: ["./event-participant-mail-import.component.scss"],
})
export class EventParticipantMailImportComponent {
    @Input()
    public modalRef?: BsModalRef;

    public csvFilename?: string;
    public selectedFile?: File | SafeUrl;

    public fileProcessStatus: FileProcessStatus = FileProcessStatus.none;
    public FileProcessStatus = FileProcessStatus;

    public progress = 0;

    public validMails: string[] = [];
    public invalidMails: string[] = [];
    public invalidMailList?: string;

    @Output()
    public newParticipantMails = new EventEmitter<string[]>();

    constructor(private translateService: TranslateService, private modalService: BsModalService) {}

    validateCSV(csvFile: File | SafeUrl) {
        this.fileProcessStatus = FileProcessStatus.none;

        if (!(csvFile instanceof File)) {
            return;
        }

        this.progress = 0;

        const reader = new FileReader();

        reader.onprogress = (event) => {
            if (event.loaded && event.total) {
                const percent = (event.loaded / event.total) * 100;

                this.fileProcessStatus = FileProcessStatus.uploading;
                this.progress = percent;
            }
        };

        reader.onload = (event) => {
            if (typeof reader.result !== "string") {
                this.fileProcessStatus = FileProcessStatus.uploadError;
                return;
            }

            this.fileProcessStatus = FileProcessStatus.uploading;

            this.validMails = [];
            this.invalidMails = [];
            this.invalidMailList = "";

            reader.result
                .replace(/\r\n/g, "\n")
                .split("\n")
                .map((email) => email.trim())
                .filter((email) => email !== "")
                .map((email) => {
                    const isValidMail = isEmail(email);

                    if (isValidMail) {
                        this.validMails.push(email);
                    } else {
                        this.invalidMails.push(email);
                    }
                });

            if (this.invalidMails.length > 0) {
                this.invalidMailList = this.invalidMails.join(", ");
            }

            this.fileProcessStatus = FileProcessStatus.finished;
        };

        reader.onabort = (event) => {
            this.fileProcessStatus = FileProcessStatus.uploadAborted;
        };

        reader.onerror = (event) => {
            this.fileProcessStatus = FileProcessStatus.uploadError;

            // eslint-disable-next-line no-console
            console.error(event);
        };

        reader.readAsText(csvFile);
    }

    import() {
        if (this.invalidMails.length > 0) {
            const initialState = {
                title: this.translateService.instant("events.detail.participantMails.import.invalidMailsExists.title"),
                message: this.translateService.instant("events.detail.participantMails.import.invalidMailsExists.message"),
                closeBtnName: this.translateService.instant("global.buttons.no"),
                confirmBtnName: this.translateService.instant("global.buttons.yes"),
            };
            const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
            if (bsModalRef.content) {
                bsModalRef.content.confirmCallback = () => {
                    this.emitAndHide();
                };
            }
        } else {
            this.emitAndHide();
        }
    }

    hide() {
        this.modalRef?.hide();
    }

    private emitAndHide() {
        this.newParticipantMails.emit(this.validMails);
        this.modalRef?.hide();
    }
}
