import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { ApiListQueryParameter } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { AdminEventCreateRequestDTO, AdminEventDetailResponseDTO, AdminEventListResponseDTO, AdminEventRoomListResponseDTO, AdminEventUpdateRequestDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IEventRepositoryConfig {
    eventsAPI: string;
}

export const EventRepositoryConfig = new InjectionToken<IEventRepositoryConfig>("EventRepositoryConfig");

@Injectable()
export class EventsRepository {
    constructor(@Inject(EventRepositoryConfig) private eventRepositoryConfig: IEventRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public list(params: ApiListQueryParameter): Observable<{ list: AdminEventListResponseDTO[]; count: number; total: number }> {
        return this.http
            .get<Object[]>(this.getServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params,
                observe: "response",
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(AdminEventListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total,
                    };
                })
            );
    }

    public read(id: string): Observable<AdminEventDetailResponseDTO> {
        return this.http
            .get<Object>(this.getServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminEventDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public create(dto: AdminEventCreateRequestDTO, backgroundImageFile?: File): Observable<AdminEventDetailResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(""), this.objectAndFileToFormData(dto, backgroundImageFile), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                observe: "response",
            })
            .pipe(map((result) => plainToInstance(AdminEventDetailResponseDTO, result.body, { excludeExtraneousValues: true })));
    }

    public update(id: string, dto: AdminEventUpdateRequestDTO, backgroundImageFile?: File): Observable<AdminEventDetailResponseDTO> {
        return this.http
            .put<Object>(this.getServiceUrl(id), this.objectAndFileToFormData(dto, backgroundImageFile), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                observe: "response",
            })
            .pipe(map((result) => plainToInstance(AdminEventDetailResponseDTO, result.body, { excludeExtraneousValues: true })));
    }

    public delete(id: string): Observable<AdminEventDetailResponseDTO> {
        return this.http
            .delete<Object>(this.getServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminEventDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public downloadBackgroundImageFile(id: string) {
        return this.http.get(this.getServiceUrl(id + "/background"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob",
        });
    }

    public getAvailableRooms(): Observable<AdminEventRoomListResponseDTO[]> {
        return this.http
            .get<Object[]>(this.getServiceUrl("/rooms"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminEventRoomListResponseDTO, result, { excludeExtraneousValues: true })));
    }

    private objectAndFileToFormData(dto: AdminEventCreateRequestDTO | AdminEventUpdateRequestDTO, backgroundImageFile?: File) {
        const formData = new FormData();
        formData.append("dto", JSON.stringify(instanceToPlain(dto, { excludeExtraneousValues: true })));

        if (backgroundImageFile) {
            formData.append("backgroundImageFile", backgroundImageFile, backgroundImageFile.name);
        }

        return formData;
    }

    private getServiceUrl(postfix: string): string {
        return this.eventRepositoryConfig.eventsAPI + postfix;
    }
}
