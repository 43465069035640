<div class="content__inner">
    <header class="content__title">
        <h1>{{ "events.list.title" | translate }}</h1>
        <small>{{ "events.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'EVENTS.ADD'" (click)="add()" class="btn btn-success btn--icon-text"><i class="zmdi zmdi-collection-plus"></i> {{ "events.list.add" | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'EVENTS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "events.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="name">{{ "events.props.name" | translate }}</th>
                        <th id="topic">{{ "events.props.topic" | translate }}</th>
                        <th id="fromDate">{{ "events.props.fromdate" | translate }}</th>
                        <th id="toDate">{{ "events.props.todate" | translate }}</th>

                        <th>{{ "events.props.created" | translate }}</th>
                        <th>{{ "events.props.createdBy" | translate }}</th>
                        <th>{{ "events.props.updated" | translate }}</th>
                        <th>{{ "events.props.updatedBy" | translate }}</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length !== 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.name }}</td>
                        <td>{{ row.topic }}</td>
                        <td>{{ row.fromDate | date: DATETIMEFORMAT }}</td>
                        <td>{{ row.toDate | date: DATETIMEFORMAT }}</td>

                        <td>{{ row.created | date: UTCDATETIMEFORMAT }}</td>
                        <td>{{ row.createdBy?.email ?? "" }}</td>
                        <td>{{ row.updated | date: UTCDATETIMEFORMAT }}</td>
                        <td>{{ row.updatedBy?.email ?? "" }}</td>

                        <td class="text-right nowrap">
                            <i (click)="edit(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'EVENTS.EDIT'"></i>
                            <i (click)="delete(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'EVENTS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length === 0">
                    <tr>
                        <td colspan="9" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
