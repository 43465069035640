<div *ngIf="permissionGranted">
    <div class="modal-header">
        <h4 *ngIf="MODE === 'ADD'">{{ "mediaitems.detail.add.title" | translate }}</h4>
        <h4 *ngIf="MODE === 'EDIT'">{{ "mediaitems.detail.edit.title" | translate }}</h4>
    </div>

    <form class="modal-body row" name="form" *ngIf="mediaItem" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate autocomplete="off">
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ "mediaitems.props.name" | translate }}</label>
                <input name="name" type="text" class="form-control" [(ngModel)]="mediaItem.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                <i class="form-group__bar"></i>
                <div class="invalid-tooltip">
                    {{ "mediaitems.detail.validation.name" | translate }}
                </div>
            </div>
        </div>

        <div class="col-md-12 mb-2">
            <label>{{ "mediaitems.props.type" | translate }}</label>

            <div class="radio" *ngFor="let roomMediaItemType of roomMediaItemTypes">
                <input type="radio" name="contactForm" [id]="roomMediaItemType" [value]="roomMediaItemType" [(ngModel)]="mediaItem.type" [checked]="roomMediaItemType === mediaItem.type" />
                <label class="radio__label" [for]="roomMediaItemType">{{ roomMediaItemType }}</label>
            </div>

            <i class="form-group__bar"></i>
            <div class="invalid-tooltip">
                {{ "mediaitems.detail.validation.type" | translate }}
            </div>
        </div>

        <div class="col-md-12 mb-2" *ngIf="mediaItem.type === 'Download'">
            <app-file-upload i18nKey="mediaitems.detail.{{ MODE === 'ADD' ? 'add' : 'edit' }}.file" name="file" [(ngModel)]="mediaItem.file" [(file)]="selectedFile" [submitted]="f.submitted"></app-file-upload>
        </div>

        <div class="col-md-12" *ngIf="mediaItem.type === '3QSDNMedia'">
            <div class="form-group">
                <label>{{ "mediaitems.props.video3qsdnId" | translate }}</label>
                <input name="video3qsdnId" type="text" class="form-control" [(ngModel)]="mediaItem.video3qsdnId" #video3qsdnId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && video3qsdnId.invalid }" required />
                <i class="form-group__bar"></i>
                <div class="invalid-tooltip">
                    {{ "mediaitems.detail.validation.video3qsdnId" | translate }}
                </div>
            </div>
        </div>

        <div class="col-md-12" *ngIf="mediaItem.type === 'FlipHTML'">
            <div class="form-group">
                <label>{{ "mediaitems.props.flipHtmlLink" | translate }}</label>
                <input name="flipHtmlLink" type="text" class="form-control" [(ngModel)]="mediaItem.flipHtmlLink" #flipHtmlLink="ngModel" [ngClass]="{ 'is-invalid': f.submitted && flipHtmlLink.invalid }" required />
                <i class="form-group__bar"></i>
                <div class="invalid-tooltip">
                    {{ "mediaitems.detail.validation.flipHtmlLink" | translate }}
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <app-file-upload
                i18nKey="mediaitems.detail.{{ MODE === 'ADD' ? 'add' : 'edit' }}.thumbnail"
                name="thumbnail"
                [(ngModel)]="mediaItem.thumbnail"
                [(file)]="selectedThumbnail"
                [submitted]="f.submitted"
                [allowedExtensions]="'jpg|jpeg|png'"
                [showPreview]="true"
            ></app-file-upload>
        </div>

        <div class="col-md-12">
            <button class="float-right btn btn-success btn--icon-text ml-2" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons." + (MODE === "ADD" ? "add" : "save") | translate }}</button>
            <button type="button" (click)="hide()" class="float-right btn btn-secondary btn--icon-text"><i class="zmdi zmdi-check"></i> {{ "global.buttons.close" | translate }}</button>
        </div>
    </form>
</div>
