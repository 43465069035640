<div class="content__inner">
    <header class="content__title">
        <h1>{{ "participantaccess.list.title" | translate }}</h1>
        <small>{{ "participantaccess.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'PARTICIPANTACCESS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "participantaccess.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="email">{{ "participantaccess.props.email" | translate }}</th>
                        <th id="event">{{ "participantaccess.props.event" | translate }}</th>
                        <th id="token">{{ "participantaccess.props.token" | translate }}</th>

                        <th>{{ "participantaccess.props.created" | translate }}</th>
                        <th>{{ "participantaccess.props.updated" | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length !== 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.user?.email }}</td>
                        <td>{{ row.event?.name }}</td>
                        <td>{{ row.passwordlessLoginToken }}</td>

                        <td>{{ row.created | date: UTCDATETIMEFORMAT }}</td>
                        <td>{{ row.updated | date: UTCDATETIMEFORMAT }}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length === 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
