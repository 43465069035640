import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { RoomsComponent } from "./rooms.component";
import { RoomServiceConfig, RoomsService, IRoomServiceConfig } from "./rooms.service";
import { RoomDetailComponent } from "./roomdetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";
import { OAuthProtectionModule } from "@impacgroup/angular-next-oauth-protection";
import { Route, RouterModule } from "@angular/router";
import { RoomsMainComponent } from "./rooms.main.component";
import { RoomsRepository, IRoomRepositoryConfig, RoomRepositoryConfig } from "./rooms.repository";
import { TabsModule } from "ngx-bootstrap/tabs";
import { IMediaItemServiceConfig, MediaItemServiceConfig, MediaItemsService } from "./components/mediaitems/mediaitems.service";
import { IMediaItemRepositoryConfig, MediaItemRepositoryConfig, MediaItemsRepository } from "./components/mediaitems/mediaitems.repository";
import { MediaItemsComponent } from "./components/mediaitems/mediaitems.component";
import { MediaItemDetailComponent } from "./components/mediaitems/mediaitemdetail.component";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";
import { RoomBaseDataComponent } from "./components/room-base-data/room-base-data.component";
import { RoomCometChatDataComponent } from "./components/room-comet-chat-data/room-comet-chat-data.component";
import { RoomDataComponent } from "./components/room-data/room-data.component";
import { AdminComponentsModule } from "src/app/components/admin-components.module";

@NgModule({
    declarations: [RoomsMainComponent, RoomsComponent, RoomDetailComponent, MediaItemsComponent, MediaItemDetailComponent, RoomBaseDataComponent, RoomCometChatDataComponent, RoomDataComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DataTablesModule,
        BsDropdownModule,
        ModalModule,
        NgSelectModule,
        TranslateModule,
        OAuthProtectionModule,
        QuillModule,
        BsDatepickerModule,
        TimepickerModule,
        TabsModule,
        AdminComponentsModule,
    ],
    exports: [],
    providers: [],
})
export class RoomsModule {
    static forRoot(config: IRoomServiceConfig & IRoomRepositoryConfig & IMediaItemServiceConfig & IMediaItemRepositoryConfig): ModuleWithProviders<RoomsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: RoomsModule,
            providers: [
                RoomsService,
                {
                    provide: RoomServiceConfig,
                    useValue: config,
                },
                RoomsRepository,
                {
                    provide: RoomRepositoryConfig,
                    useValue: config,
                },
                MediaItemsService,
                {
                    provide: MediaItemServiceConfig,
                    useValue: config,
                },
                MediaItemsRepository,
                {
                    provide: MediaItemRepositoryConfig,
                    useValue: config,
                },
                PendingChangesGuard,
            ],
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: RoomsMainComponent,
                children: [
                    {
                        path: "",
                        component: RoomsComponent,
                    },
                    {
                        path: "add",
                        component: RoomDetailComponent,
                        canDeactivate: [PendingChangesGuard],
                    },
                    {
                        path: ":id",
                        component: RoomDetailComponent,
                        canDeactivate: [PendingChangesGuard],
                    },
                ],
            },
        ];
    }
}
