import { Component } from "@angular/core";
import { ConfirmDialogComponent } from "@impacgroup/angular-next-baselib";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { EventsService } from "../../events.service";
import { EventDetailAvailableRoomModel } from "../../viewmodels/Event";
import { EventDataComponent } from "../event-data/event-data.component";

@Component({
    selector: "app-event-available-rooms",
    templateUrl: "./event-available-rooms.component.html",
    styleUrls: ["./event-available-rooms.component.scss"],
})
export class EventAvailableRoomsComponent extends EventDataComponent {
    public UTCDATETIMEFORMAT: string = "";

    constructor(private eventService: EventsService, private translateService: TranslateService, private modalService: BsModalService) {
        super();
        this.UTCDATETIMEFORMAT = this.eventService.UTCDATETIMEFORMAT;
    }

    public changeRoomCheckbox(event: Event, room: EventDetailAvailableRoomModel) {
        if (!this.event) {
            return;
        }

        const target = event.target as HTMLInputElement;
        const newCheckboxState = target.checked;

        if (newCheckboxState === false) {
            // Prevent toggling checkbox until the modal is answered (or not)
            target.checked = !newCheckboxState;
            room.assigned = !newCheckboxState;
            this.unassignRoom(event, room);
        }
    }

    private unassignRoom(event: Event, room: EventDetailAvailableRoomModel) {
        if (!this.event) {
            return;
        }

        const target = event.target as HTMLInputElement;

        const initialState = {
            title: this.translateService.instant("events.detail.availableRooms.unassign.title"),
            message: this.translateService.instant("events.detail.availableRooms.unassign.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok"),
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (!bsModalRef.content) {
            return;
        }
        bsModalRef.content.confirmCallback = () => {
            if (!this.event || !room._id) {
                return;
            }

            room.assigned = false;
            target.checked = false;
        };
    }
}
