export class PermissionsConfig {
    static readonly PERMISSIONS = {
        AUTHENTICATED: {
            andScopes: ["authenticated"],
            orScopes: [],
        },
        GLOBAL: {
            // User may switch organizations
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: [],
        },
        "MENU.USERMANAGEMENT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read", "admin.role.read", "admin.organization.read"],
        },
        "MENU.USERMANAGEMENT.USERS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"],
        },
        "MENU.USERMANAGEMENT.ROLES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"],
        },
        "MENU.USERMANAGEMENT.ORGANIZATIONS": {
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: [],
        },
        "MENU.LANGUAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"],
        },
        "MENU.MAIL": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read", "admin.mailtemplate.read"],
        },
        "MENU.MAIL.MAILACCOUNTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"],
        },
        "MENU.MAIL.MAILTEMPLATES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"],
        },
        "MENU.INVITATIONS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.invitation.read"],
        },
        "MENU.REGISTRATION": {
            andScopes: ["authenticated"],
            orScopes: ["admin.registrationconfig.read"],
        },
        "MENU.PARTICIPANTACCESS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.eventparticipants.read"],
        },
        "USERMANAGEMENT.USERS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.create"],
        },
        "USERMANAGEMENT.USERS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"],
        },
        "USERMANAGEMENT.USERS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.update"],
        },
        "USERMANAGEMENT.USERS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"],
        },
        "USERMANAGEMENT.USERS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.delete"],
        },
        "USERMANAGEMENT.ROLES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.create"],
        },
        "USERMANAGEMENT.ROLES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"],
        },
        "USERMANAGEMENT.ROLES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.update"],
        },
        "USERMANAGEMENT.ROLES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.delete"],
        },
        "USERMANAGEMENT.ORGANIZATIONS.ADD": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.create"],
        },
        "USERMANAGEMENT.ORGANIZATIONS.LIST": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.read"],
        },
        "USERMANAGEMENT.ORGANIZATIONS.EDIT": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.update"],
        },
        "USERMANAGEMENT.ORGANIZATIONS.DELETE": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.delete"],
        },
        "LANGUAGES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.create"],
        },
        "LANGUAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"],
        },
        "LANGUAGES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.update"],
        },
        "LANGUAGES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.delete"],
        },
        "MAIL.MAILTEMPLATES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.create"],
        },
        "MAIL.MAILTEMPLATES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"],
        },
        "MAIL.MAILTEMPLATES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.update"],
        },
        "MAIL.MAILTEMPLATES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.delete"],
        },
        "MAIL.MAILACCOUNTS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.create"],
        },
        "MAIL.MAILACCOUNTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"],
        },
        "MAIL.MAILACCOUNTS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.update"],
        },
        "MAIL.MAILACCOUNTS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.delete"],
        },
        "INVITATIONS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.invitation.create"],
        },
        "INVITATIONS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.invitation.read"],
        },
        "INVITATIONS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.invitation.update"],
        },
        "INVITATIONS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.invitation.delete"],
        },
        "REGISTRATIONCONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.registrationconfig.update"],
        },
        "MENU.ROOMS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.read"],
        },
        "ROOMS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.create"],
        },
        "ROOMS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.read"],
        },
        "ROOMS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.update"],
        },
        "ROOMS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.delete"],
        },
        "ROOMMEDIAITEMS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.update"],
        },
        "ROOMMEDIAITEMS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.read"],
        },
        "ROOMMEDIAITEMS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.update"],
        },
        "ROOMMEDIAITEMS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.room.update"],
        },
        "MENU.EVENTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.event.read"],
        },
        "EVENTS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.event.create"],
        },
        "EVENTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.event.read"],
        },
        "EVENTS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.event.update"],
        },
        "EVENTS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.event.delete"],
        },
        "PARTICIPANTACCESS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.eventparticipants.read"],
        },
    };
}
