import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class AppService {
    // Default theme
    maTheme: string = "blue-grey";

    // Mobile sidebar state
    mobileSidebarActive: boolean = false;

    constructor() {}
}
