import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { ApiListQueryParameter } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { AdminEventParticipantListResponseDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IParticipantAccessRepositoryConfig {
    participantAccessAPI: string;
}

export const ParticipantAccessRepositoryConfig = new InjectionToken<IParticipantAccessRepositoryConfig>("ParticipantAccessRepositoryConfig");

@Injectable()
export class ParticipantAccessRepository {
    constructor(@Inject(ParticipantAccessRepositoryConfig) private participantAccessRepositoryConfig: IParticipantAccessRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public list(params: ApiListQueryParameter): Observable<{ list: AdminEventParticipantListResponseDTO[]; count: number; total: number }> {
        return this.http
            .get<Object[]>(this.getServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params,
                observe: "response",
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(AdminEventParticipantListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total,
                    };
                })
            );
    }

    private getServiceUrl(postfix: string): string {
        return this.participantAccessRepositoryConfig.participantAccessAPI + postfix;
    }
}
