<ng-container *ngIf="event">
    <ng-template tabHeading> {{ "events.detail.tabs.participantMails" | translate }}<i class="ml-2 zmdi zmdi-circle text-transparent" [ngClass]="{ 'text-primary': dirty }"></i> </ng-template>

    <div class="card">
        <div class="card-body">
            <button type="button" *ngIf="permissionGranted" (click)="openAddParticipantMailModal()" class="btn btn-success btn--icon-text">
                <i class="zmdi zmdi-collection-plus"></i> {{ "events.detail.participantMails.buttons.add" | translate }}
            </button>
            <button type="button" *ngIf="permissionGranted" (click)="openAddParticipantMailsByCSVModal()" class="btn btn-success btn--icon-text float-right">
                <i class="zmdi zmdi-collection-plus"></i> {{ "events.detail.participantMails.buttons.import" | translate }}
            </button>
        </div>
    </div>

    <ng-template #patricipantMailAddModalTemplate>
        <app-event-participant-mail-add [modalRef]="patricipantMailAddModalRef" (newParticipantMail)="addParticipantMail($event)"></app-event-participant-mail-add>
    </ng-template>

    <ng-template #patricipantMailImportModalTemplate>
        <app-event-participant-mail-import [modalRef]="patricipantMailImportModalRef" (newParticipantMails)="addParticipantMails($event)"></app-event-participant-mail-import>
    </ng-template>

    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "events.detail.participantMails.title" | translate }}</h4>
            <h6 class="card-subtitle">{{ "events.detail.participantMails.subtitle" | translate }}</h6>

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th id="mail">{{ "events.detail.participantMails.table.mail" | translate }}</th>
                        <th class="part-mail-options"></th>
                    </tr>
                </thead>
                <tbody *ngIf="(event.participantMails?.length ?? 0) > 0">
                    <ng-container *ngFor="let participantMail of event.participantMails; let i = index">
                        <tr>
                            <td>{{ participantMail }}</td>
                            <td class="text-right nowrap">
                                <i (click)="deletePartifipantMail(participantMail)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'EVENTS.DELETE'"></i>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="event.participantMails?.length === 0">
                    <tr>
                        <td colspan="2" class="no-data-available">{{ "events.detail.participantMails.table.noParticipantMails" | translate }}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!event.participantMails">
                    <tr>
                        <td colspan="2" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</ng-container>
