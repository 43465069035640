import { Component, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BaseComponentMode } from "@impacgroup/angular-next-baselib";
import { RoomDetailModel } from "../../viewmodels/Room";

@Component({
    selector: "app-room-data",
    templateUrl: "./room-data.component.html",
    styleUrls: ["./room-data.component.scss"],
})
export class RoomDataComponent {
    @ViewChild("f", { static: true }) form?: NgForm;

    @Input()
    public MODE?: BaseComponentMode;

    @Input()
    public room?: RoomDetailModel;
}
