import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

import { AlertService, ApiErrorResponse } from "@impacgroup/angular-next-baselib";
import { OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { HttpLoadingService } from "./HttpLoadingService";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private alertService: AlertService, private httpLoadingService: HttpLoadingService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.httpLoadingService.increment();
        return next.handle(req).pipe(
            catchError((res: HttpErrorResponse) => {
                this.httpLoadingService.decrement();
                if (res?.error?.id && res?.error?.code) {
                    const errorResponse: ApiErrorResponse = {
                        ...res.error,
                        message: (res.error.message ?? "").replace(/\n/g, "<br/>"),
                    };

                    this.alertService.addError(errorResponse);

                    if (errorResponse.code === "authentication_required") {
                        OAuthStorageAdapter.clearStorage();
                        this.router.navigate(["/login"]);
                    }
                } else {
                    if (res.status === 503) {
                        this.alertService.addCustomErrorByI18nId("global.errors.timeout");
                    } else {
                        this.alertService.addUnknownError(res);
                    }
                }
                return throwError(() => res);
            }),
            finalize(() => {
                this.httpLoadingService.decrement();
            })
        );
    }
}
