import { Component } from "@angular/core";
import { AlertService } from "@impacgroup/angular-next-baselib";

@Component({
    selector: "app-layout",
    templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.scss"],
})
export class SingleLayoutComponent {
    constructor(public alertService: AlertService) {}
}
