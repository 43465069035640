<div class="content__inner" *appHasPermission="'ROOMS.EDIT'">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "rooms.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "rooms.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <tabset *ngIf="room">
        <tab>
            <app-room-base-data
                #roomBaseData
                [MODE]="MODE"
                [room]="room"
                [(selectedBackgroundImage)]="selectedBackgroundImage"
                [(selectedMobileBackgroundImage)]="selectedMobileBackgroundImage"
                [(selectedPreviewImage)]="selectedPreviewImage"
            ></app-room-base-data>
        </tab>

        <tab>
            <app-room-comet-chat-data #roomCometChatData [MODE]="MODE" [room]="room"></app-room-comet-chat-data>
        </tab>

        <tab [heading]="'rooms.detail.tabs.medialibrary' | translate" *ngIf="MODE === 'EDIT'">
            <app-mediaitems [roomId]="room._id"></app-mediaitems>
        </tab>
    </tabset>

    <div class="col-md-12">
        <button type="button" (click)="save()" class="float-right btn btn-success btn--icon-text" [disabled]="disableSaveButton && formsAreInvalid()"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
    </div>
</div>
