import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MediaItemsService } from "./mediaitems.service";
import { AlertService, BaseComponent, BaseComponentMode, BaseDatatableComponent, ConfirmDialogComponent, DataTableParameters } from "@impacgroup/angular-next-baselib";
import { MediaItemListModel } from "./viewmodels/MediaItem";
import { RoomsService } from "../../rooms.service";

@Component({
    selector: "app-mediaitems",
    templateUrl: "./mediaitems.component.html",
    styleUrls: ["mediaitems.component.scss"],
})
export class MediaItemsComponent extends BaseComponent implements OnInit {
    public UTCDATETIMEFORMAT: string = "";

    rows: MediaItemListModel[] = [];

    @Input()
    public roomId?: string;

    @ViewChild("menuItemModalTemplate")
    public menuItemModalTemplate?: TemplateRef<any>;
    public menuItemModalRef?: BsModalRef;

    public mediaItemId?: string;
    public mediaItemMode = BaseComponentMode.ADD;

    constructor(
        private roomsService: RoomsService,
        private mediaItemsService: MediaItemsService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService
    ) {
        super();
        this.UTCDATETIMEFORMAT = this.roomsService.UTCDATETIMEFORMAT;
    }
    ngOnInit(): void {
        if (this.roomId) {
            this.initEditMode();
        } else {
            throw Error("There must be an room id.");
        }
    }

    private initEditMode() {
        this.refreshList();
    }

    public refreshList() {
        if (!this.roomId) {
            return;
        }

        this.subscriptions.push(
            this.mediaItemsService.list(this.roomId, {}).subscribe((result) => {
                this.rows = result.list;
            })
        );
    }

    openAddMediaItemModal() {
        if (!this.roomId) {
            return;
        }

        this.mediaItemId = undefined;
        this.mediaItemMode = BaseComponentMode.ADD;

        if (this.menuItemModalTemplate) {
            this.menuItemModalRef = this.modalService.show(this.menuItemModalTemplate, { class: "modal-lg" });
        }
    }

    openEditMediaItemModal(id?: string) {
        if (!this.roomId || !id) {
            return;
        }

        this.mediaItemId = id;
        this.mediaItemMode = BaseComponentMode.EDIT;

        if (this.menuItemModalTemplate) {
            this.menuItemModalRef = this.modalService.show(this.menuItemModalTemplate, { class: "modal-lg" });
        }
    }

    delete(id?: string) {
        const initialState = {
            title: this.translateService.instant("mediaitems.list.delete.title"),
            message: this.translateService.instant("mediaitems.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok"),
        };

        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (this.roomId && id) {
                    this.subscriptions.push(
                        this.mediaItemsService.delete(this.roomId, id).subscribe((result) => {
                            this.alertService.addSuccess(this.translateService.instant("mediaitems.list.delete.success"));
                            this.refreshList();
                        })
                    );
                }
            };
        }
    }
}
