<form name="form" #f="ngForm" novalidate autocomplete="off">
    <ng-container *ngIf="room && room.cometChat">
        <ng-template tabHeading>
            {{ "rooms.detail.tabs.cometchat" | translate }}<i class="ml-2 zmdi zmdi-circle text-transparent" [ngClass]="{ 'text-primary': f.dirty && (f.valid || !f.submitted), 'text-red': f.dirty && f.invalid && f.submitted }"></i
        ></ng-template>

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ "rooms.detail.cometchat.title" | translate }}</h4>
                <h6 class="card-subtitle">{{ "rooms.detail.cometchat.subtitle" | translate }}</h6>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="cometChatActive" name="cometChatActive" [(ngModel)]="room.cometChat.active" />
                                <label class="checkbox__label" for="cometChatActive"> {{ "rooms.props.cometchat.active" | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="cometChatDocked" name="cometChatDocked" [(ngModel)]="room.cometChat.docked" />
                                <label class="checkbox__label" for="cometChatDocked"> {{ "rooms.props.cometchat.docked" | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="roundedCorners" name="roundedCorners" [(ngModel)]="room.cometChat.roundedCorners" />
                                <label class="checkbox__label" for="roundedCorners"> {{ "rooms.props.cometchat.roundedCorners" | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.cometchat.appId" | translate }}</label>
                            <input name="cometChatAppId" type="text" class="form-control" [(ngModel)]="room.cometChat.appId" #cometChatAppId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cometChatAppId.invalid }" />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.cometchat.appId" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.cometchat.appRegion" | translate }}</label>
                            <input name="cometChatAppRegion" type="text" class="form-control" [(ngModel)]="room.cometChat.appRegion" #cometChatAppRegion="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cometChatAppRegion.invalid }" />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.cometchat.appRegion" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.cometchat.authKey" | translate }}</label>
                            <input name="cometChatAuthKey" type="text" class="form-control" [(ngModel)]="room.cometChat.authKey" #cometChatAuthKey="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cometChatAuthKey.invalid }" />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.cometchat.authKey" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.cometchat.widgetId" | translate }}</label>
                            <input name="cometChatWidgetId" type="text" class="form-control" [(ngModel)]="room.cometChat.widgetId" #cometChatWidgetId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cometChatWidgetId.invalid }" />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.cometchat.widgetId" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.cometchat.alignment" | translate }}</label>
                            <input name="cometChatAlignment" type="text" class="form-control" [(ngModel)]="room.cometChat.alignment" #cometChatAlignment="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cometChatAlignment.invalid }" />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.cometchat.alignment" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
