import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponentMode } from "@impacgroup/angular-next-baselib";
import { EventsService } from "./events.service";
import { TranslateService } from "@ngx-translate/core";
import { EventDetailModel } from "./viewmodels/Event";
import { EventBaseDataComponent } from "./components/event-base-data/event-base-data.component";
import { Observable } from "rxjs";
import { BsModalService } from "ngx-bootstrap/modal";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { EventParticipantMailsComponent } from "./components/event-participant-mails/event-participant-mails.component";
import { CanDeactivateBaseCompoment } from "src/app/guards/pending-changes.guard";
import { EventMeetPSComponent } from "./components/event-meetps/event-meetps.component";

@Component({
    selector: "app-eventdetail",
    templateUrl: "./eventdetail.component.html",
    styleUrls: ["eventdetail.component.scss"],
})
export class EventDetailComponent extends CanDeactivateBaseCompoment {
    public eventId?: string;
    public event?: EventDetailModel;

    public selectedBackgroundImage?: File | SafeUrl;

    public disableSaveButton = false;

    @ViewChild("eventBaseData", { static: false }) eventBaseDataComponent?: EventBaseDataComponent;
    @ViewChild("eventAvailableRooms", { static: false }) eventAvailableRoomsComponent?: EventBaseDataComponent;
    @ViewChild("eventParticipantMails", { static: false }) eventParticipantMails?: EventParticipantMailsComponent;
    @ViewChild("eventMeetPS", { static: false }) eventMeetPSComponent?: EventMeetPSComponent;

    constructor(
        private route: ActivatedRoute,
        private eventsService: EventsService,
        private router: Router,
        private alertService: AlertService,
        protected override translateService: TranslateService,
        protected override modalService: BsModalService,
        private changeDetector: ChangeDetectorRef,
        private sanitization: DomSanitizer
    ) {
        super(translateService, modalService);

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.eventId = params["id"];
                if (this.eventId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;

        this.eventsService.generate().subscribe({
            next: (result) => {
                this.event = result;
            },
        });
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.eventId) {
            return;
        }

        const readDetailsSubscription = this.eventsService.read(this.eventId).subscribe({
            next: (result) => {
                this.event = result;

                if (this.eventId) {
                    this.downloadImageFile({ filename: this.event.backgroundImageFile, imagepropertyname: "selectedBackgroundImage", observable: this.eventsService.downloadBackgroundImageFile(this.eventId) });
                }
            },
            error: (err) => {
                this.router.navigate(["../"], { relativeTo: this.route });
            },
        });

        this.subscriptions.push(readDetailsSubscription);
    }

    save() {
        this.disableSaveButton = true;
        this.submitForms();

        if (this.formsAreInvalid()) {
            return;
        }

        if (this.event) {
            let newBackgroundImage: File | undefined = undefined;
            if (this.selectedBackgroundImage instanceof File) {
                newBackgroundImage = this.selectedBackgroundImage;
            }

            if (this.MODE === BaseComponentMode.ADD) {
                this.subscriptions.push(
                    this.eventsService.create(this.event, newBackgroundImage).subscribe({
                        next: (result) => {
                            this.resetForms();
                            this.alertService.addSuccess(this.translateService.instant("events.detail.add.success"));
                            this.router.navigate(["../", result._id], { relativeTo: this.route });
                        },
                        error: () => {
                            this.disableSaveButton = false;
                        },
                    })
                );
            }

            if (this.MODE === BaseComponentMode.EDIT) {
                this.subscriptions.push(
                    this.eventsService.update(this.event, newBackgroundImage).subscribe({
                        next: (result) => {
                            this.resetForms();
                            this.alertService.addSuccess(this.translateService.instant("events.detail.edit.success"));
                            this.changeDetector.detectChanges();
                            this.event = result;
                        },
                        error: () => {
                            this.disableSaveButton = false;
                        },
                    })
                );
            }
        }
    }

    back() {
        this.router.navigate(["../"], { relativeTo: this.route });
    }

    public formsAreInvalid(): boolean | null | undefined {
        return this.eventBaseDataComponent?.form?.invalid || this.eventAvailableRoomsComponent?.form?.invalid || this.eventMeetPSComponent?.form?.invalid;
    }

    protected formsAreDirty(): boolean | null | undefined {
        return this.eventBaseDataComponent?.form?.dirty || this.eventAvailableRoomsComponent?.form?.dirty || this.eventParticipantMails?.dirty || this.eventMeetPSComponent?.form?.dirty;
    }

    private submitForms() {
        this.eventBaseDataComponent?.form?.onSubmit(new Event(""));
        this.eventAvailableRoomsComponent?.form?.onSubmit(new Event(""));
        this.eventMeetPSComponent?.form?.onSubmit(new Event(""));
    }

    private resetForms() {
        this.eventBaseDataComponent?.form?.resetForm();
        this.eventAvailableRoomsComponent?.form?.resetForm();
        this.eventMeetPSComponent?.form?.resetForm();
        if (this.eventParticipantMails) {
            this.eventParticipantMails.dirty = false;
        }
    }

    private downloadImageFile({ filename, imagepropertyname, observable }: { filename: string | undefined; imagepropertyname: "selectedBackgroundImage"; observable: Observable<Blob> }) {
        if (filename) {
            const downloadSubscription = observable.subscribe((image) => {
                const reader = new FileReader();

                reader.readAsDataURL(image);
                reader.onload = (_event) => {
                    const trustedUrl = this.sanitization.bypassSecurityTrustUrl(`${reader.result}`);
                    this[imagepropertyname] = trustedUrl;
                };
            });

            this.subscriptions.push(downloadSubscription);
        }
    }
}
