<form name="form" #f="ngForm" novalidate autocomplete="off">
    <ng-container *ngIf="event">
        <ng-template tabHeading>
            {{ "events.detail.tabs.meetps" | translate }}<i class="ml-2 zmdi zmdi-circle text-transparent" [ngClass]="{ 'text-primary': f.dirty && (f.valid || !f.submitted), 'text-red': f.dirty && f.invalid && f.submitted }"></i>
        </ng-template>

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ "events.detail.meetps.title" | translate }}</h4>
                <h6 class="card-subtitle">{{ "events.detail.meetps.subtitle" | translate }}</h6>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="event_meetps_enabled" name="enabled" [(ngModel)]="event.meetPS.enabled" />
                                <label class="checkbox__label" for="event_meetps_enabled">{{ "events.props.meetps.enabled" | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="event_meetps_userLogin" name="userLogin" [(ngModel)]="event.meetPS.userLogin" />
                                <label class="checkbox__label" for="event_meetps_userLogin">{{ "events.props.meetps.userLogin" | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ "events.props.meetps.url" | translate }}</label>
                            <input name="url" type="text" class="form-control" [(ngModel)]="event.meetPS.url" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "events.props.meetps.client" | translate }}</label>
                            <input name="client" type="text" class="form-control" [(ngModel)]="event.meetPS.client" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "events.props.meetps.secret" | translate }}</label>
                            <input name="secret" type="text" class="form-control" [(ngModel)]="event.meetPS.secret" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
