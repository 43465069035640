import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { roundTo5Minutes } from "src/app/utils/date";
import { EventDataComponent } from "../event-data/event-data.component";

@Component({
    selector: "app-event-base-data",
    templateUrl: "./event-base-data.component.html",
    styleUrls: ["./event-base-data.component.scss"],
})
export class EventBaseDataComponent extends EventDataComponent {
    @Input() selectedBackgroundImage?: File | SafeUrl;
    @Output() selectedBackgroundImageChange = new EventEmitter<File | SafeUrl>();

    changeFromDate(date: Date) {
        if (!this.event) {
            return;
        }

        if (!this.event.fromDate) {
            roundTo5Minutes(date);
        }

        this.event.fromDate = date;
    }

    changeToDate(date: Date) {
        if (!this.event) {
            return;
        }

        if (!this.event.toDate) {
            roundTo5Minutes(date);
        }

        this.event.toDate = date;
    }

    /*
        https://github.com/valor-software/ngx-bootstrap/issues/5864
        https://github.com/valor-software/ngx-bootstrap/pull/5334
        Ticket / Pull request seems unresolved to me, workaroud:
    */

    pickerChangeFromDate(date: Date) {
        if (!this.event) {
            return;
        }

        const markAsDirty = !!this.event.fromDate;

        if (this.event.fromDate !== date) {
            this.event.fromDate = date;

            if (markAsDirty) {
                this.markFormAsDirty();
            }
        }
    }

    pickerChangeToDate(date: Date) {
        if (!this.event) {
            return;
        }

        const markAsDirty = !!this.event.toDate;

        if (this.event.toDate !== date) {
            this.event.toDate = date;

            if (markAsDirty) {
                this.markFormAsDirty();
            }
        }
    }

    pickerOnKeyDown(event: KeyboardEvent) {
        if (event.key.length === 1) {
            this.markFormAsDirty();
        }
    }

    private markFormAsDirty() {
        this.form?.form.markAsDirty();
    }
}
