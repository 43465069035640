<form name="form" #f="ngForm" novalidate autocomplete="off">
    <ng-container *ngIf="event">
        <ng-template tabHeading>
            {{ "events.detail.tabs.basedata" | translate }}<i class="ml-2 zmdi zmdi-circle text-transparent" [ngClass]="{ 'text-primary': f.dirty && (f.valid || !f.submitted), 'text-red': f.dirty && f.invalid && f.submitted }"></i>
        </ng-template>

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ "events.detail.basedata.title" | translate }}</h4>
                <h6 class="card-subtitle">{{ "events.detail.basedata.subtitle" | translate }}</h6>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ "events.props.name" | translate }}</label>
                            <input name="name" type="text" class="form-control" [(ngModel)]="event.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "events.detail.basedata.validation.name" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ "events.props.topic" | translate }}</label>
                            <input name="topic" type="text" class="form-control" [(ngModel)]="event.topic" #topic="ngModel" [ngClass]="{ 'is-invalid': f.submitted && topic.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "events.detail.basedata.validation.topic" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{ "events.props.fromdate" | translate }}</label>
                                    <input
                                        name="fromDate"
                                        type="text"
                                        class="form-control"
                                        [ngModel]="event.fromDate"
                                        (ngModelChange)="changeFromDate($event)"
                                        #fromDate="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && fromDate.invalid }"
                                        placeholder="DD.MM.YYYY"
                                        bsDatepicker
                                        [bsConfig]="{ adaptivePosition: true, isAnimated: true, containerClass: 'date-picker', dateInputFormat: 'DD.MM.YYYY' }"
                                        required
                                    />
                                    <i class="form-group__bar"></i>
                                    <div class="invalid-tooltip">
                                        {{ "events.detail.basedata.validation.fromdate" | translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <timepicker [ngModel]="event.fromDate" (ngModelChange)="pickerChangeFromDate($event)" (keydown)="pickerOnKeyDown($event)" [showMeridian]="false" [ngModelOptions]="{ standalone: true }"></timepicker>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{ "events.props.todate" | translate }}</label>
                                    <input
                                        name="toDate"
                                        type="text"
                                        class="form-control"
                                        [ngModel]="event.toDate"
                                        (ngModelChange)="changeToDate($event)"
                                        #toDate="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && toDate.invalid }"
                                        placeholder="DD.MM.YYYY"
                                        bsDatepicker
                                        [bsConfig]="{ adaptivePosition: true, isAnimated: true, containerClass: 'date-picker', dateInputFormat: 'DD.MM.YYYY' }"
                                        required=""
                                    />
                                    <i class="form-group__bar"></i>
                                    <div class="invalid-tooltip">
                                        {{ "events.detail.basedata.validation.todate" | translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <timepicker [ngModel]="event.toDate" (ngModelChange)="pickerChangeToDate($event)" (keydown)="pickerOnKeyDown($event)" [showMeridian]="false" [ngModelOptions]="{ standalone: true }"></timepicker>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ "events.props.videoid" | translate }}</label>
                            <input name="videoId" type="text" class="form-control" [(ngModel)]="event.videoId" #videoId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && videoId.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "events.detail.basedata.validation.videoid" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <app-file-upload
                            i18nKey="events.detail.{{ MODE === 'ADD' ? 'add' : 'edit' }}.backgroundImageFile"
                            name="backgroundImageFile"
                            [(ngModel)]="event.backgroundImageFile"
                            [file]="selectedBackgroundImage"
                            (fileChange)="selectedBackgroundImageChange.emit($event)"
                            [submitted]="f.submitted"
                            [allowedExtensions]="'jpg|jpeg|png'"
                            [showPreview]="true"
                        ></app-file-upload>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
