<form name="form" #f="ngForm" novalidate autocomplete="off">
    <ng-container *ngIf="room">
        <ng-template tabHeading>
            {{ "rooms.detail.tabs.basedata" | translate }}<i class="ml-2 zmdi zmdi-circle text-transparent" [ngClass]="{ 'text-primary': f.dirty && (f.valid || !f.submitted), 'text-red': f.dirty && f.invalid && f.submitted }"></i
        ></ng-template>

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ "rooms.detail.basedata.title" | translate }}</h4>
                <h6 class="card-subtitle">{{ "rooms.detail.basedata.subtitle" | translate }}</h6>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.name" | translate }}</label>
                            <input name="name" type="text" class="form-control" [(ngModel)]="room.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.name" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.adboardlink" | translate }}</label>
                            <input name="adboardLink" type="text" class="form-control" [(ngModel)]="room.adboardLink" #adboardLink="ngModel" [ngClass]="{ 'is-invalid': f.submitted && adboardLink.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.adboardlink" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "rooms.props.videoid" | translate }}</label>
                            <input name="videoId" type="text" class="form-control" [(ngModel)]="room.videoId" #videoId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && videoId.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "rooms.detail.validation.videoid" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label>{{ "rooms.props.contactFormType" | translate }}</label>

                        <div class="radio" *ngFor="let contactFormType of contactFormTypes">
                            <input type="radio" name="contactForm" [id]="contactFormType" [value]="contactFormType" [(ngModel)]="room.contactForm" [checked]="contactFormType === room.contactForm" />
                            <label class="radio__label" [for]="contactFormType">{{ contactFormType }}</label>
                        </div>

                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "rooms.detail.validation.contactFormType" | translate }}
                        </div>
                    </div>

                    <div class="col-md-12 mb-4"><!-- Placeholder --></div>

                    <div class="col-md-6">
                        <app-file-upload
                            i18nKey="rooms.detail.{{ MODE === 'ADD' ? 'add' : 'edit' }}.backgroundImageFile"
                            name="backgroundImageFile"
                            [(ngModel)]="room.backgroundImageFile"
                            [file]="selectedBackgroundImage"
                            (fileChange)="selectedBackgroundImageChange.emit($event)"
                            [submitted]="f.submitted"
                            [allowedExtensions]="'jpg|jpeg|png'"
                            [showPreview]="true"
                        ></app-file-upload>
                    </div>

                    <div class="col-md-6">
                        <app-file-upload
                            i18nKey="rooms.detail.{{ MODE === 'ADD' ? 'add' : 'edit' }}.mobileBackgroundImageFile"
                            name="mobileBackgroundImageFile"
                            [(ngModel)]="room.mobileBackgroundImageFile"
                            [file]="selectedMobileBackgroundImage"
                            (fileChange)="selectedMobileBackgroundImageChange.emit($event)"
                            [submitted]="f.submitted"
                            [allowedExtensions]="'jpg|jpeg|png'"
                            [showPreview]="true"
                        ></app-file-upload>
                    </div>

                    <div class="col-md-12 mb-4"><!-- Placeholder --></div>

                    <div class="col-md-6">
                        <app-file-upload
                            i18nKey="rooms.detail.{{ MODE === 'ADD' ? 'add' : 'edit' }}.previewImageFile"
                            name="previewImageFile"
                            [(ngModel)]="room.previewImageFile"
                            [file]="selectedPreviewImage"
                            (fileChange)="selectedPreviewImageChange.emit($event)"
                            [submitted]="f.submitted"
                            [allowedExtensions]="'jpg|jpeg|png'"
                            [showPreview]="true"
                        ></app-file-upload>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
