<div *appHasPermission="'EVENTS.EDIT'">
    <div class="modal-header">
        <h4>{{ "events.detail.participantMails.add.title" | translate }}</h4>
    </div>

    <form class="modal-body row" name="form" (ngSubmit)="f.form.valid && add()" #f="ngForm" novalidate autocomplete="off">
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ "events.detail.participantMails.mail" | translate }}</label>
                <input name="email" type="text" class="form-control" [(ngModel)]="mail" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email required />
                <i class="form-group__bar"></i>
                <div class="invalid-tooltip">
                    {{ "events.detail.participantMails.validation.mail" | translate }}
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <button class="float-right btn btn-success btn--icon-text ml-2" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.add" | translate }}</button>
            <button type="button" (click)="hide()" class="float-right btn btn-secondary btn--icon-text"><i class="zmdi zmdi-check"></i> {{ "global.buttons.close" | translate }}</button>
        </div>
    </form>
</div>
