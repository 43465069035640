import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { EventsService } from "./events.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, DataTableParameters } from "@impacgroup/angular-next-baselib";
import { EventListModel } from "./viewmodels/Event";
import { EventsMainComponent } from "./events.main.component";

@Component({
    selector: "app-events",
    templateUrl: "./events.component.html",
    styleUrls: ["events.component.scss"],
})
export class EventsComponent extends BaseDatatableComponent {
    public UTCDATETIMEFORMAT: string = "";
    public DATETIMEFORMAT: string = "";

    rows: EventListModel[] = [];

    constructor(
        private eventsService: EventsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: EventsMainComponent
    ) {
        super(translateService, {
            stateSaveMode: eventsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_events_list",
        });

        this.dtOptions.order = [[4, "desc"]];
        this.UTCDATETIMEFORMAT = this.eventsService.UTCDATETIMEFORMAT;
        this.DATETIMEFORMAT = this.eventsService.DATETIMEFORMAT;
    }

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback: Function) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.eventsService.list(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: [],
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "name", orderable: true },
            { data: "topic", orderable: true },
            { data: "fromDate", orderable: true },
            { data: "toDate", orderable: true },

            { data: "created", orderable: true },
            { data: "createdBy", orderable: true },
            { data: "updated", orderable: true },
            { data: "updatedBy", orderable: true },
            { data: "actions", orderable: false },
        ];
    }

    add() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    edit(id?: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    delete(id?: string) {
        const initialState = {
            title: this.translateService.instant("events.list.delete.title"),
            message: this.translateService.instant("events.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok"),
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (id) {
                    this.subscriptions.push(
                        this.eventsService.delete(id).subscribe((result) => {
                            this.alertService.addSuccess(this.translateService.instant("events.list.delete.success"));
                            this.rerenderDatatable();
                        })
                    );
                }
            };
        }
    }
}
