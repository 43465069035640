<div class="content__inner">
    <header class="card">
        <div class="card-body">
            <button type="button" *appHasPermission="'ROOMMEDIAITEMS.ADD'" (click)="openAddMediaItemModal()" class="btn btn-success btn--icon-text"><i class="zmdi zmdi-collection-plus"></i> {{ "mediaitems.list.add" | translate }}</button>
        </div>
    </header>

    <ng-template #menuItemModalTemplate>
        <app-mediaitemdetail [MODE]="mediaItemMode" [modalRef]="menuItemModalRef" [roomId]="roomId" [mediaItemId]="mediaItemId" (mediaItemsListChanged)="refreshList()"></app-mediaitemdetail>
    </ng-template>

    <div class="card" *appHasPermission="'ROOMMEDIAITEMS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "mediaitems.list.card.title" | translate }}</h4>
            <h6 class="card-subtitle">{{ "mediaitems.list.card.subtitle" | translate }}</h6>

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th id="name">{{ "mediaitems.props.name" | translate }}</th>
                        <th id="type">{{ "mediaitems.props.type" | translate }}</th>

                        <th>{{ "mediaitems.props.created" | translate }}</th>
                        <th>{{ "mediaitems.props.createdBy" | translate }}</th>
                        <th>{{ "mediaitems.props.updated" | translate }}</th>
                        <th>{{ "mediaitems.props.updatedBy" | translate }}</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length !== 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.name }}</td>
                        <td>{{ row.type }}</td>

                        <td>{{ row.created | date: UTCDATETIMEFORMAT }}</td>
                        <td>{{ row.createdBy?.email ?? "" }}</td>
                        <td>{{ row.updated | date: UTCDATETIMEFORMAT }}</td>
                        <td>{{ row.updatedBy?.email ?? "" }}</td>

                        <td class="text-right nowrap">
                            <i (click)="openEditMediaItemModal(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'ROOMMEDIAITEMS.EDIT'"></i>
                            <i (click)="delete(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'ROOMMEDIAITEMS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length === 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
