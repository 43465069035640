import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { ParticipantAccessRepository } from "./participantaccess.repository";
import { ParticipantAccessListModel } from "./viewmodels/ParticipantAccess";

export interface IParticipantAccessServiceConfig {
    utcDateTimeFormat: string;
    dateTimeFormat: string;
    dateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const ParticipantAccessServiceConfig = new InjectionToken<IParticipantAccessServiceConfig>("ParticipantAccessServiceConfig");

@Injectable()
export class ParticipantAccessService {
    public UTCDATETIMEFORMAT: string = "";
    public DATETIMEFORMAT: string = "";
    public DATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(ParticipantAccessServiceConfig) private participantAccessConfig: IParticipantAccessServiceConfig, private participantAccessRepository: ParticipantAccessRepository) {
        this.UTCDATETIMEFORMAT = this.participantAccessConfig.utcDateTimeFormat;
        this.DATETIMEFORMAT = this.participantAccessConfig.dateTimeFormat;
        this.DATEFORMAT = this.participantAccessConfig.dateFormat;
        this.datatableStateSaveMode = this.participantAccessConfig.datatableStateSaveMode;
    }

    public list(params: ApiListQueryParameter): Observable<{ list: ParticipantAccessListModel[]; count: number; total: number }> {
        return this.participantAccessRepository.list(params).pipe(
            map((result) => {
                return {
                    list: result.list.map((dto) => {
                        return {
                            ...dto,
                        };
                    }),
                    count: result.count,
                    total: result.total,
                };
            })
        );
    }
}
