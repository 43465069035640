import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-next-baselib";

@Component({
    selector: "app-rooms-main",
    templateUrl: "./rooms.main.component.html",
})
export class RoomsMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}
