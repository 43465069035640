import { Injectable, InjectionToken, Inject } from "@angular/core";
import { forkJoin, Observable, of, OperatorFunction } from "rxjs";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { EventsRepository } from "./events.repository";
import { EventDetailAvailableRoomModel, EventDetailModel, EventListModel, factoryGenerateEventDetailModel } from "./viewmodels/Event";
import { AdminEventCreateRequestDTO, AdminEventDetailResponseDTO, AdminEventRoomListResponseDTO, AdminEventUpdateRequestDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IEventServiceConfig {
    utcDateTimeFormat: string;
    dateTimeFormat: string;
    dateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const EventServiceConfig = new InjectionToken<IEventServiceConfig>("EventServiceConfig");

@Injectable()
export class EventsService {
    public UTCDATETIMEFORMAT: string = "";
    public DATETIMEFORMAT: string = "";
    public DATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(EventServiceConfig) private eventConfig: IEventServiceConfig, private eventsRepository: EventsRepository) {
        this.UTCDATETIMEFORMAT = this.eventConfig.utcDateTimeFormat;
        this.DATETIMEFORMAT = this.eventConfig.dateTimeFormat;
        this.DATEFORMAT = this.eventConfig.dateFormat;
        this.datatableStateSaveMode = this.eventConfig.datatableStateSaveMode;
    }

    public list(params: ApiListQueryParameter): Observable<{ list: EventListModel[]; count: number; total: number }> {
        return this.eventsRepository.list(params).pipe(
            map((result) => {
                return {
                    list: result.list.map((dto) => {
                        return {
                            ...dto,
                        };
                    }),
                    count: result.count,
                    total: result.total,
                };
            })
        );
    }

    public generate(): Observable<EventDetailModel> {
        return this.getAvailableRooms().pipe(
            map((roomList) => {
                const eventDetails = factoryGenerateEventDetailModel();

                return {
                    ...eventDetails,
                    availableRooms: roomList.map((room) => {
                        return {
                            ...room,
                            assigned: false,
                        };
                    }),
                };
            })
        );
    }

    public read(id: string): Observable<EventDetailModel> {
        return forkJoin([this.eventsRepository.read(id), this.getAvailableRooms()]).pipe(this.convertEventDetailDTOtoViewModel());
    }

    public create(obj: EventDetailModel, backgroundImageFile?: File): Observable<EventDetailModel> {
        return forkJoin([this.eventsRepository.create(plainToInstance(AdminEventCreateRequestDTO, this.convertEventDetailViewModeltoDTO(obj), { excludeExtraneousValues: true }), backgroundImageFile), this.getAvailableRooms()]).pipe(
            this.convertEventDetailDTOtoViewModel()
        );
    }

    public update(obj: EventDetailModel, backgroundImageFile?: File): Observable<EventDetailModel> {
        if (!obj._id) {
            throw new Error("Cannot update object without _id");
        }

        return forkJoin([
            this.eventsRepository.update(obj._id, plainToInstance(AdminEventUpdateRequestDTO, this.convertEventDetailViewModeltoDTO(obj), { excludeExtraneousValues: true }), backgroundImageFile),
            this.getAvailableRooms(),
        ]).pipe(this.convertEventDetailDTOtoViewModel());
    }

    public delete(id: string): Observable<EventDetailModel> {
        return forkJoin([this.eventsRepository.delete(id), this.getAvailableRooms()]).pipe(this.convertEventDetailDTOtoViewModel());
    }

    public downloadBackgroundImageFile(id: string): Observable<Blob> {
        return this.eventsRepository.downloadBackgroundImageFile(id);
    }

    public getAvailableRooms(): Observable<EventDetailAvailableRoomModel[]> {
        return this.eventsRepository.getAvailableRooms().pipe(
            map((result) =>
                result.map((room) => {
                    return {
                        ...room,
                    };
                })
            )
        );
    }

    private convertEventDetailDTOtoViewModel(): OperatorFunction<[AdminEventDetailResponseDTO, AdminEventRoomListResponseDTO[]], EventDetailModel> {
        return map((result) => {
            const eventDetails = result[0];
            const roomList = result[1];

            return {
                ...eventDetails,
                fromTime: eventDetails.fromDate,
                toTime: eventDetails.toDate,
                backgroundImageFile: eventDetails.backgroundImageFile?.filename,
                availableRooms: roomList.map((room) => {
                    return {
                        ...room,
                        assigned: eventDetails.availableRooms?.includes(room._id ?? ""),
                    };
                }),
                meetPS: {
                    enabled: eventDetails.meetPS?.enabled ?? false,
                    userLogin: eventDetails.meetPS?.userLogin ?? false,
                    url: eventDetails.meetPS?.url ?? "",
                    client: eventDetails.meetPS?.client ?? "",
                    secret: eventDetails.meetPS?.secret ?? "",
                },
            };
        });
    }

    private convertEventDetailViewModeltoDTO(model: EventDetailModel): AdminEventCreateRequestDTO | AdminEventUpdateRequestDTO {
        return {
            ...model,
            availableRooms: model.availableRooms
                ?.filter((room) => room.assigned)
                .map((room) => room._id)
                .filter((roomId): roomId is string => !!roomId),
        };
    }
}
