import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-next-oauth-protection";
import { ApiListQueryParameter } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { AdminRoomCreateRequestDTO, AdminRoomDetailResponseDTO, AdminRoomListResponseDTO, AdminRoomUpdateRequestDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IRoomRepositoryConfig {
    roomsAPI: string;
}

export const RoomRepositoryConfig = new InjectionToken<IRoomRepositoryConfig>("RoomRepositoryConfig");

@Injectable()
export class RoomsRepository {
    constructor(@Inject(RoomRepositoryConfig) private roomRepositoryConfig: IRoomRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public list(params: ApiListQueryParameter): Observable<{ list: AdminRoomListResponseDTO[]; count: number; total: number }> {
        return this.http
            .get<Object[]>(this.getServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params,
                observe: "response",
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(AdminRoomListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total,
                    };
                })
            );
    }

    public read(id: string): Observable<AdminRoomDetailResponseDTO> {
        return this.http
            .get<Object>(this.getServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminRoomDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public create({
        dto,
        backgroundImageFile,
        mobileBackgroundImageFile,
        previewImageFile,
    }: {
        dto: AdminRoomCreateRequestDTO;
        backgroundImageFile?: File;
        mobileBackgroundImageFile?: File;
        previewImageFile?: File;
    }): Observable<AdminRoomDetailResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(""), this.objectAndFileToFormData({ dto, backgroundImageFile, mobileBackgroundImageFile, previewImageFile }), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                observe: "response",
            })
            .pipe(
                map((result) => {
                    return plainToInstance(AdminRoomDetailResponseDTO, result.body, { excludeExtraneousValues: true });
                })
            );
    }

    public update({
        id,
        dto,
        backgroundImageFile,
        mobileBackgroundImageFile,
        previewImageFile,
    }: {
        id: string;
        dto: AdminRoomUpdateRequestDTO;
        backgroundImageFile?: File;
        mobileBackgroundImageFile?: File;
        previewImageFile?: File;
    }): Observable<AdminRoomDetailResponseDTO> {
        return this.http
            .patch<object>(this.getServiceUrl(id), this.objectAndFileToFormData({ dto, backgroundImageFile, mobileBackgroundImageFile, previewImageFile }), {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
                observe: "response",
            })
            .pipe(map((result) => plainToInstance(AdminRoomDetailResponseDTO, result.body, { excludeExtraneousValues: true })));
    }

    public delete(id: string): Observable<AdminRoomDetailResponseDTO> {
        return this.http
            .delete<Object>(this.getServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            })
            .pipe(map((result) => plainToInstance(AdminRoomDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public downloadBackgroundImageFile(id: string) {
        return this.http.get(this.getServiceUrl(id + "/background"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob",
        });
    }

    public downloadMobileBackgroundImageFile(id: string) {
        return this.http.get(this.getServiceUrl(id + "/mobilebackground"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob",
        });
    }

    public downloadPreviewImageFile(id: string) {
        return this.http.get(this.getServiceUrl(id + "/preview"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob",
        });
    }

    private objectAndFileToFormData({
        dto,
        backgroundImageFile,
        mobileBackgroundImageFile,
        previewImageFile,
    }: {
        dto: AdminRoomUpdateRequestDTO | AdminRoomCreateRequestDTO;
        backgroundImageFile?: File;
        mobileBackgroundImageFile?: File;
        previewImageFile?: File;
    }) {
        const formData = new FormData();
        formData.append("dto", JSON.stringify(instanceToPlain(dto, { excludeExtraneousValues: true })));

        if (backgroundImageFile) {
            formData.append("backgroundImageFile", backgroundImageFile, backgroundImageFile.name);
        }

        if (mobileBackgroundImageFile) {
            formData.append("mobileBackgroundImageFile", mobileBackgroundImageFile, mobileBackgroundImageFile.name);
        }

        if (previewImageFile) {
            formData.append("previewImageFile", previewImageFile, previewImageFile.name);
        }

        return formData;
    }

    private getServiceUrl(postfix: string): string {
        return this.roomRepositoryConfig.roomsAPI + postfix;
    }
}
