import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { EventsComponent } from "./events.component";
import { EventServiceConfig, EventsService, IEventServiceConfig } from "./events.service";
import { EventDetailComponent } from "./eventdetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";
import { OAuthProtectionModule } from "@impacgroup/angular-next-oauth-protection";
import { Route, RouterModule } from "@angular/router";
import { EventsMainComponent } from "./events.main.component";
import { EventsRepository, IEventRepositoryConfig, EventRepositoryConfig } from "./events.repository";
import { TabsModule } from "ngx-bootstrap/tabs";
import { EventDataComponent } from "./components/event-data/event-data.component";
import { EventBaseDataComponent } from "./components/event-base-data/event-base-data.component";
import { EventAvailableRoomsComponent } from "./components/event-available-rooms/event-available-rooms.component";
import { EventParticipantMailsComponent } from "./components/event-participant-mails/event-participant-mails.component";
import { AdminComponentsModule } from "src/app/components/admin-components.module";
import { EventParticipantMailAddComponent } from "./components/event-participant-mail-add/event-participant-mail-add.component";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";
import { EventParticipantMailImportComponent } from "./components/event-participant-mail-import/event-participant-mail-import.component";
import { EventMeetPSComponent } from "./components/event-meetps/event-meetps.component";

@NgModule({
    declarations: [
        EventsMainComponent,
        EventsComponent,
        EventDetailComponent,
        EventDataComponent,
        EventBaseDataComponent,
        EventAvailableRoomsComponent,
        EventParticipantMailsComponent,
        EventParticipantMailAddComponent,
        EventParticipantMailImportComponent,
        EventMeetPSComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DataTablesModule,
        BsDropdownModule,
        ModalModule,
        NgSelectModule,
        TranslateModule,
        OAuthProtectionModule,
        QuillModule,
        BsDatepickerModule,
        TimepickerModule,
        TabsModule,
        AdminComponentsModule,
    ],
    exports: [],
    providers: [],
})
export class EventsModule {
    static forRoot(config: IEventServiceConfig & IEventRepositoryConfig): ModuleWithProviders<EventsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: EventsModule,
            providers: [
                EventsService,
                {
                    provide: EventServiceConfig,
                    useValue: config,
                },
                EventsRepository,
                {
                    provide: EventRepositoryConfig,
                    useValue: config,
                },
            ],
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: EventsMainComponent,
                children: [
                    {
                        path: "",
                        component: EventsComponent,
                    },
                    {
                        path: "add",
                        component: EventDetailComponent,
                        canDeactivate: [PendingChangesGuard],
                    },
                    {
                        path: ":id",
                        component: EventDetailComponent,
                        canDeactivate: [PendingChangesGuard],
                    },
                ],
            },
        ];
    }
}
