import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable, OperatorFunction } from "rxjs";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-next-baselib";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { RoomsRepository } from "./rooms.repository";
import { RoomDetailModel, RoomListModel } from "./viewmodels/Room";
import { AdminRoomCreateRequestDTO, AdminRoomDetailResponseDTO, AdminRoomUpdateRequestDTO } from "@impacgroup/gsk-event-platform-api-dtos";

export interface IRoomServiceConfig {
    utcDateTimeFormat: string;
    dateTimeFormat: string;
    dateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const RoomServiceConfig = new InjectionToken<IRoomServiceConfig>("RoomServiceConfig");

@Injectable()
export class RoomsService {
    public UTCDATETIMEFORMAT: string = "";
    public DATETIMEFORMAT: string = "";
    public DATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(RoomServiceConfig) private roomConfig: IRoomServiceConfig, private roomsRepository: RoomsRepository) {
        this.UTCDATETIMEFORMAT = this.roomConfig.utcDateTimeFormat;
        this.DATETIMEFORMAT = this.roomConfig.dateTimeFormat;
        this.DATEFORMAT = this.roomConfig.dateFormat;
        this.datatableStateSaveMode = this.roomConfig.datatableStateSaveMode;
    }

    public list(params: ApiListQueryParameter): Observable<{ list: RoomListModel[]; count: number; total: number }> {
        return this.roomsRepository.list(params).pipe(
            map((result) => {
                return {
                    list: result.list.map((dto) => {
                        return {
                            ...dto,
                        };
                    }),
                    count: result.count,
                    total: result.total,
                };
            })
        );
    }

    public read(id: string): Observable<RoomDetailModel> {
        return this.roomsRepository.read(id).pipe(this.convertRoomDetailDTOtoViewModel());
    }

    public create({ obj, backgroundImageFile, mobileBackgroundImageFile, previewImageFile }: { obj: RoomDetailModel; backgroundImageFile?: File; mobileBackgroundImageFile?: File; previewImageFile?: File }): Observable<RoomDetailModel> {
        return this.roomsRepository
            .create({
                dto: plainToInstance(AdminRoomCreateRequestDTO, obj, { excludeExtraneousValues: true }),
                backgroundImageFile,
                mobileBackgroundImageFile,
                previewImageFile,
            })
            .pipe(this.convertRoomDetailDTOtoViewModel());
    }

    public update({ obj, backgroundImageFile, mobileBackgroundImageFile, previewImageFile }: { obj: RoomDetailModel; backgroundImageFile?: File; mobileBackgroundImageFile?: File; previewImageFile?: File }): Observable<RoomDetailModel> {
        if (!obj._id) {
            throw new Error("Cannot update object without _id");
        }

        return this.roomsRepository
            .update({
                id: obj._id,
                dto: plainToInstance(AdminRoomUpdateRequestDTO, obj, { excludeExtraneousValues: true }),
                backgroundImageFile,
                mobileBackgroundImageFile,
                previewImageFile,
            })
            .pipe(this.convertRoomDetailDTOtoViewModel());
    }

    public delete(id: string): Observable<RoomDetailModel> {
        return this.roomsRepository.delete(id).pipe(this.convertRoomDetailDTOtoViewModel());
    }

    public downloadBackgroundImageFile(id: string): Observable<Blob> {
        return this.roomsRepository.downloadBackgroundImageFile(id);
    }

    public downloadMobileBackgroundImageFile(id: string): Observable<Blob> {
        return this.roomsRepository.downloadMobileBackgroundImageFile(id);
    }

    public downloadPreviewImageFile(id: string): Observable<Blob> {
        return this.roomsRepository.downloadPreviewImageFile(id);
    }

    private convertRoomDetailDTOtoViewModel(): OperatorFunction<AdminRoomDetailResponseDTO, RoomDetailModel> {
        return map((result) => {
            return {
                ...result,
                backgroundImageFile: result.backgroundImageFile?.filename,
                mobileBackgroundImageFile: result.mobileBackgroundImageFile?.filename,
                previewImageFile: result.previewImageFile?.filename,
            };
        });
    }
}
